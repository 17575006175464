import { atom, useAtom } from "jotai";
import { useEffect, useState } from "react";
import { atomCheckID } from "../uebersicht";
import { atomChangesDone } from "../../App";
import { atomNumOfSelectedFeststellungen } from "./ModulHeader";
import DeckungenParent from "./DeckungenParent";
import directus from "../../lib/directus";


async function getFeststellungen(deckung) {
    try {
        const { data } = await directus.items('feststellungen').readByQuery({
            filter: {
                fkDeckungen: deckung.idDeckungen
            }
        });
        return data;
    } catch (error) {
    }
}

async function getAuswertungen(deckung, check) {
    const { data } = await directus.items('auswertungen').readByQuery({
        filter: {
            fkDeckung: { _eq: deckung.idDeckungen },
            fkCheck: { _eq: check },
        }
    });
    return data;
}

export const atomRisk = atom([]);
export const atomRiskList = atom([]);
export const atomItemsToRemove = atom([]);

export default function Deckungen(
    {
        i,
        setFeststellungsValue,
        feststellungsValue,
        deckung,
        isUnterposition,
        setListOfRisks,
        setNumOfFeststellungen,
        updateData,
    }
) {
    //Variable with the number of selected "feststellungen" aka feststellungs counter
    const [numOfSelectedFeststellungen, setNumOfSelectedFeststellungen] = useAtom(atomNumOfSelectedFeststellungen)
    //the id of the Check
    const [idCheck] = useAtom(atomCheckID);
    //Variable with all the changes done by the user
    const [changesDone, setChangesDone] = useAtom(atomChangesDone)
    //VAriable with all the data from the userInputs
    const [auswertungen, setAuswertungen] = useState({})
    //Variable with the name of the "status" (grey, green, yellow, red circle)
    const [riskName, setRiskName] = useState('riskDotNeutral');
    //Variable with all the "feststellungen"
    const [feststellungen, setFeststellungen] = useState(['']);

    const [feststellungenToSave, setFeststellungenToSave] = useState(['']);

    const [newData, setNewData] = useState({})

    const [risk, setRisk] = useAtom(atomRisk);

    const [riskList, setRiskList] = useAtom(atomRiskList);

    //Variable to Save the selected value from dropdown
    const [value, setValue] = useState(() => { });

    const [bemerkung, setBemerkung] = useState(() => { });

    const [itemsToRemove, setItemsToRemove] = useAtom(atomItemsToRemove);
    //Importing data from mydb/feststellungen where data is from "deckung"
    useEffect(() => {
        async function fetchData() {
            const result = await getFeststellungen(deckung);
            const auswertung = await getAuswertungen(deckung, idCheck);
            setFeststellungen(result);
            setAuswertungen(auswertung);
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    //useEffect hook to save the corect circle color
    useEffect(() => {
        const foundRisk = risk?.find(feststellung => feststellung.fkDeckung === deckung.idDeckungen)?.status;
        if (foundRisk === 0) {
            setRiskName("riskDotLow");
        } else if (foundRisk === 1) {
            setRiskName("riskDotMid");
        } else if (foundRisk === 2) {
            setRiskName("riskDotHigh");
        } else if (foundRisk === 3) {
            setRiskName("riskDotNothing");
        } else {
            setRiskName("riskDotNeutral");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    useEffect(() => {
        async function setValues() {

            if (auswertungen[0]) {
                const condition = await auswertungen[0].fkFeststellung
                const resultAuswertung = feststellungen?.find(feststellung => feststellung.idFeststellungen === condition);
                try {
                    const valueIsSetIndex = riskList.findIndex(item => item.fkDeckung === resultAuswertung.fkDeckungen);
                    if (valueIsSetIndex !== -1) {
                        setValue(riskList[valueIsSetIndex])
                    } else {
                        setValue(resultAuswertung);
                    }
                    } catch (error) {
                }
                try {
                    setRisk(prevRisk => {
                        const index = prevRisk.findIndex(item => item.fkDeckung === deckung.idDeckungen);
                        if (index !== -1) {
                            const updatedList = [...prevRisk];
                            updatedList[index] = { "status": resultAuswertung.status, "fkFeststellung": resultAuswertung.idFeststellungen, "fkDeckung": deckung.idDeckungen };
                            return updatedList;
                        } else {
                            return [...prevRisk, { "status": resultAuswertung.status, "fkFeststellung": resultAuswertung.idFeststellungen, "fkDeckung": deckung.idDeckungen }];
                        }
                    });
                    setListOfRisksHandler(resultAuswertung);
                    setFeststellungsValue(prevState => {
                        const index = prevState.findIndex(item => item.idDeckung === deckung.idDeckungen);
                        if (index !== -1) {
                            const updatedList = [...prevState];
                            updatedList[index] = { 'idDeckung': deckung.idDeckungen, "abhaengigkeit": deckung.abhaengigkeit, "versteckt": deckung.versteckt, "modulNr": deckung.modulNr, "feststellung": resultAuswertung };
                            return updatedList;
                        } else {
                            return [...prevState, { 'idDeckung': deckung.idDeckungen, "abhaengigkeit": deckung.abhaengigkeit, "versteckt": deckung.versteckt, "modulNr": deckung.modulNr, "feststellung": resultAuswertung }];
                        }
                    })

                    /*     saveToLocalStorage(feststellungsNr, resultAuswertung);
                        localStorage.setItem("risk" + feststellungsNr, JSON.stringify(resultAuswertung.status));
                     */
                } catch (error) {
                }
                try {
                    setBemerkung(auswertungen[0].bemerkungen);
                } catch (error) {
                }
            }
        }
        if(!value){
            setValues();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feststellungen, auswertungen])

    // TODO: solve this by using atom instead of drilling down updateData
    useEffect(() => {
        if (Object.keys(newData).length !== 0) {
            updateData(newData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newData])


    useEffect(() => { 
            if (feststellungsValue?.some((feststellung) => feststellung.idDeckung === deckung.idDeckungen)) {
                setValue(feststellungsValue.find((feststellung) => feststellung.idDeckung === deckung.idDeckungen).feststellung);
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (deckung.modulNr === feststellungsValue?.find((feststellung) => feststellung?.idDeckung === deckung.idDeckungen)?.modulNr) {
            // add one entry to setNumOfSelectedFeststellungen where the deckung.modulNr is the key and the value is the number of feststellungsValue that have the same modulNr as deckung.modulNr
            setNumOfFeststellungen(numOfFeststellungen => ({ ...numOfFeststellungen, [deckung.modulNr]: feststellungsValue.filter((feststellung) => feststellung?.modulNr === deckung.modulNr).length }));
            setListOfRisksHandler(feststellungsValue.find((feststellung) => feststellung?.idDeckung === deckung.idDeckungen).feststellung);
            /* 
            setNumOfFeststellungen(feststellungsValue.filter(entry => entry !== null && entry !== undefined && entry !== '').length);
         */}

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feststellungsValue, value])
    useEffect(() => {
        const parsedObj = feststellungenToSave?.find((feststellung) => feststellung.idDeckungen === deckung.idDeckungen)?.feststellung;
        async function fetchData() {
            if (bemerkung || parsedObj) {
                if (parsedObj && itemsToRemove?.indexOf(parsedObj.fkDeckungen) === -1) {
                    setNewData({
                        fkDeckung: parsedObj.fkDeckungen,
                        deckungName: deckung.deckungName,
                        kommentar: bemerkung,
                        fkFeststellung: parsedObj.idFeststellungen,
                        fkCheck: idCheck,
                        feststellungName: parsedObj.feststellungName,
                        fkBranche: deckung.fkBranchen,
                        versteckt: deckung.versteckt,
                        abhaengigkeit: deckung.abhaengigkeit,
                    })
                } else {
                    setNewData({
                        fkDeckung: deckung.idDeckungen,
                        deckungName: deckung.deckungName,
                        kommentar: bemerkung,
                        fkFeststellung: '',
                        fkCheck: idCheck,
                        feststellungName: '',
                        fkBranche: deckung.fkBranchen,
                        versteckt: deckung.versteckt,
                        abhaengigkeit: deckung.abhaengigkeit,
                    })
                }
            }
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bemerkung, value]);

    const removeHandler = async (deckungen) => {
        if (changesDone === true) {
            await setItemsToRemove(prevItemsToRemove => [...new Set([...prevItemsToRemove, ...deckungen])]);
        }
    }
    const setListOfRisksHandler = (chosenObj) => {
        setListOfRisks(listOfRisks => {
            const updatedList = [...listOfRisks];
            const index = updatedList.findIndex(item => item.key === i);
            if (index !== -1) {
                updatedList[index] = { "key": i, "status": chosenObj.status, "istInAuswertung": deckung.istInAuswertung };
                let indexToRemove = []
                const deckungGroups = {
                    1: [25, 26],
                    2: [25, 26, 27, 28, 29],
                    3: [31, 32],
                    4: [31, 32, 33, 34, 35],
                    5: [37, 38],
                    6: [37, 38, 39, 40, 41],
                    7: [43, 44],
                    8: [43, 44, 45, 46, 47],
                    9: [64, 65],
                    10: [64, 65, 66, 67, 68],
                    11: [72],
                    12: [52],
                    13: [1, 2]
                }
                //if item contains subposition, update it too
                switch (chosenObj.fkDeckungen) {
                    case 24:
                        switch (chosenObj.status) {
                            // ##Risk Low (Green)##
                            case 0:
                                deckungGroups[1].forEach(subPosition => {
                                    const indexSubPosition = updatedList.findIndex(item => item.key === subPosition);
                                    if (indexSubPosition !== -1) {
                                        indexToRemove.push(indexSubPosition);
                                    }
                                })
                                // Search for any entries in itemsToRemove that are equal to any value inside deckungGroups[2] if there are any, remove them from itemsToRemove
                                if (itemsToRemove.some(item => deckungGroups[2].includes(item))) {
                                    new Promise(resolve => {
                                        setItemsToRemove(itemsToRemove.filter(item => !deckungGroups[2].includes(item)));
                                        resolve();
                                    }).then(() => {
                                        removeHandler(deckungGroups[1]);
                                    });
                                } else {
                                    removeHandler(deckungGroups[1]);
                                }
                                break;
                            // ##Risk Medium (Yellow)##
                            case 1:
                                if (itemsToRemove.some(item => deckungGroups[2].includes(item))) {
                                    new Promise(resolve => {
                                        setItemsToRemove(itemsToRemove.filter(item => !deckungGroups[2].includes(item)));
                                        resolve();
                                    });
                                }
                                break;
                            // ##Risk Nothing (WhiteGreen)##
                            case 3:
                                deckungGroups[2].forEach(subPosition => {
                                    const indexSubPosition = updatedList.findIndex(item => item.key === subPosition);
                                    if (indexSubPosition !== -1) {
                                        indexToRemove.push(indexSubPosition);
                                    }
                                })
                                removeHandler(deckungGroups[2])
                                break;
                            default:
                                break;
                        }
                        break;
                    case 30:
                        switch (chosenObj.status) {
                            case 0:
                                if (itemsToRemove.some(item => deckungGroups[4].includes(item))) {
                                    new Promise(resolve => {
                                        setItemsToRemove(itemsToRemove.filter(item => !deckungGroups[4].includes(item)));
                                        resolve();
                                    }).then(() => {
                                        removeHandler(deckungGroups[3]);
                                    });
                                } else {
                                    removeHandler(deckungGroups[3]);
                                }
                                break;
                            case 1:
                                if (itemsToRemove.some(item => deckungGroups[4].includes(item))) {
                                    new Promise(resolve => {
                                        setItemsToRemove(itemsToRemove.filter(item => !deckungGroups[4].includes(item)));
                                        resolve();
                                    });
                                }
                                break;
                            case 3:
                                deckungGroups[4].forEach(subPosition => {
                                    const indexSubPosition = updatedList.findIndex(item => item.key === subPosition);
                                    if (indexSubPosition !== -1) {
                                        indexToRemove.push(indexSubPosition);
                                    }
                                })
                                removeHandler(deckungGroups[4])
                                break;
                            default:
                                break;
                        }
                        break;
                    case 36:
                        switch (chosenObj.status) {
                            case 0:
                                if (itemsToRemove.some(item => deckungGroups[6].includes(item))) {
                                    new Promise(resolve => {
                                        setItemsToRemove(itemsToRemove.filter(item => !deckungGroups[6].includes(item)));
                                        resolve();
                                    }).then(() => {
                                        removeHandler(deckungGroups[5]);
                                    });
                                } else {
                                    removeHandler(deckungGroups[5]);
                                }
                                break;
                            case 1:
                                if (itemsToRemove.some(item => deckungGroups[6].includes(item))) {
                                    new Promise(resolve => {
                                        setItemsToRemove(itemsToRemove.filter(item => !deckungGroups[6].includes(item)));
                                        resolve();
                                    });
                                }
                                break;
                            case 3:
                                deckungGroups[6].forEach(subPosition => {
                                    const indexSubPosition = updatedList.findIndex(item => item.key === subPosition);
                                    if (indexSubPosition !== -1) {
                                        indexToRemove.push(indexSubPosition);
                                    }
                                })
                                removeHandler(deckungGroups[6])
                                break;
                            default:
                                break;
                        }
                        break;
                    case 42:
                        switch (chosenObj.status) {
                            case 0:
                                if (itemsToRemove.some(item => deckungGroups[8].includes(item))) {
                                    new Promise(resolve => {
                                        setItemsToRemove(itemsToRemove.filter(item => !deckungGroups[8].includes(item)));
                                        resolve();
                                    }).then(() => {
                                        removeHandler(deckungGroups[7]);
                                    });
                                } else {
                                    removeHandler(deckungGroups[7]);
                                }
                                break;
                            case 1:
                                if (itemsToRemove.some(item => deckungGroups[8].includes(item))) {
                                    new Promise(resolve => {
                                        setItemsToRemove(itemsToRemove.filter(item => !deckungGroups[8].includes(item)));
                                        resolve();
                                    });
                                }
                                break;
                            case 3:
                                deckungGroups[8].forEach(subPosition => {
                                    const indexSubPosition = updatedList.findIndex(item => item.key === subPosition);
                                    if (indexSubPosition !== -1) {
                                        indexToRemove.push(indexSubPosition);
                                    }
                                })
                                removeHandler(deckungGroups[8])
                                break;
                            default:
                                break;
                        }
                        break;
                    case 70:
                        switch (chosenObj.status) {
                            case 0:
                                deckungGroups[9].forEach(subPosition => {
                                    const indexSubPosition = updatedList.findIndex(item => item.key === subPosition);
                                    if (indexSubPosition !== -1) {
                                        indexToRemove.push(indexSubPosition);
                                    }
                                })
                                removeHandler(deckungGroups[9])
                                break;
                            case 3:
                                deckungGroups[10].forEach(subPosition => {
                                    const indexSubPosition = updatedList.findIndex(item => item.key === subPosition);
                                    if (indexSubPosition !== -1) {
                                        indexToRemove.push(indexSubPosition);
                                    }
                                })
                                removeHandler(deckungGroups[10])
                                break;
                            default:
                                break;
                        }
                        break;
                    case 48:
                        switch (chosenObj.status) {
                            case 3:
                            case 0:
                                deckungGroups[11].forEach(subPosition => {
                                    const indexSubPosition = updatedList.findIndex(item => item.key === subPosition);
                                    if (indexSubPosition !== -1) {
                                        indexToRemove.push(indexSubPosition);
                                    }
                                })
                                removeHandler(deckungGroups[11])
                                break;
                            default:
                                break;
                        }
                        break;
                    case 51:
                        switch (chosenObj.feststellungName) {
                            case "nicht vorhanden":
                                deckungGroups[12].forEach(subPosition => {
                                    const indexSubPosition = updatedList.findIndex(item => item.key === subPosition);
                                    if (indexSubPosition !== -1) {
                                        indexToRemove.push(indexSubPosition);
                                    }
                                })
                                removeHandler(deckungGroups[12])
                                break;
                            default:
                                break;
                        }
                        break;
                    case 127:
                        switch (chosenObj.status) {
                            case 0:
                            case 1:
                                deckungGroups[13].forEach(subPosition => {
                                    const indexSubPosition = updatedList.findIndex(item => item.key === subPosition);
                                    if (indexSubPosition !== -1) {
                                        indexToRemove.push(indexSubPosition);
                                    }
                                })
                                removeHandler(deckungGroups[13])
                                break;
                            default:
                                break;
                        }
                        break;
                    default:
                        break;
                }
                indexToRemove.sort((a, b) => b - a).forEach(index => {
                    updatedList.splice(index);
                })
                return updatedList;
            } else {
                return [...listOfRisks, { "key": i, "status": chosenObj.statu, "istInAuswertung": deckung.istInAuswertung }];
            }
        })
    }


    //function to add the selected feststellung in the state feststellungenToSave if there is no feststellung with the same idDeckungen
    const safeSelectedFeststellungen = (newFeststellung) => {
        setFeststellungenToSave(prevState => [...prevState, { idDeckungen: deckung.idDeckungen, feststellung: newFeststellung }]);
    }

    // create a function that will update or add an object in the riskList array the value should be given through the functions passing arguments
    const updateRiskList = (value) => {
        setRiskList(prevRiskList => {
            const index = prevRiskList.findIndex(item => item.fkDeckung === deckung.idDeckungen);
            if (index !== -1) {
                const updatedList = [...prevRiskList];
                updatedList[index] = { value };
                return updatedList;
            } else {
                return [...prevRiskList, { value }];
            }
        })
    }

    
    const handleSelect = (e) => {
        const eventData = feststellungen.find(feststellung => feststellung.idFeststellungen === JSON.parse(e));
        setRisk(prevRisk => {
            const index = prevRisk.findIndex(item => item.fkDeckung === deckung.idDeckungen);
            if (index !== -1) {
                const updatedList = [...prevRisk];
                updatedList[index] = { "status": eventData.status, "fkFeststellung": eventData.idFeststellungen, "fkDeckung": deckung.idDeckungen };
                return updatedList;
            } else {
                return [...prevRisk, { "status": eventData.status, "fkFeststellung": eventData.idFeststellungen, "fkDeckung": deckung.idDeckungen }];
            }
        });
        setChangesDone(true);
        setValue(eventData);
        updateRiskList(eventData)
        // add the selected value to setFeststellungsValue with idDeckungen
        setFeststellungsValue(prevState => {
            const index = prevState.findIndex(item => item.idDeckung === deckung.idDeckungen);
            if (index !== -1) {
                const updatedList = [...prevState];
                updatedList[index] = { 'idDeckung': deckung.idDeckungen, "abhaengigkeit": deckung.abhaengigkeit, "versteckt": deckung.versteckt, "modulNr": deckung.modulNr, "feststellung": eventData };
                return updatedList;
            } else {
                return [...prevState, { 'idDeckung': deckung.idDeckungen, "abhaengigkeit": deckung.abhaengigkeit, "versteckt": deckung.versteckt, "modulNr": deckung.modulNr, "feststellung": eventData }];
            }
        })
        setListOfRisksHandler(eventData);
        safeSelectedFeststellungen(eventData);
    };
    function updateFeststellungen() {
        switch (deckung.idDeckungen) {
            //holzheizung
            case 24:
                switch (riskName) {
                    case 'riskDotLow':
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        [25, 26].forEach(id => {
                            const index = numOfSelectedFeststellungen.indexOf(id);
                            if (index > -1) {

                                numOfSelectedFeststellungen.splice(index, 1);
                            }
                        })
                        break;
                    case 'riskDotNothing':
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        [25, 26, 27, 28, 29].forEach(id => {
                            const index = numOfSelectedFeststellungen.indexOf(id);
                            if (index > -1) {

                                numOfSelectedFeststellungen.splice(index, 1);
                            }
                        })
                        break;
                    case 'riskDotNeutral':
                        break;
                    default:
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        break;
                }
                break;
            //Holzherd
            case 30:
                switch (riskName) {
                    case 'riskDotLow':
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        [31, 32].forEach(id => {
                            const index = numOfSelectedFeststellungen.indexOf(id);
                            if (index > -1) {

                                numOfSelectedFeststellungen.splice(index, 1);
                            }
                        })
                        break;
                    case 'riskDotNothing':
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        [31, 32, 33, 34, 35].forEach(id => {
                            const index = numOfSelectedFeststellungen.indexOf(id);
                            if (index > -1) {

                                numOfSelectedFeststellungen.splice(index, 1);
                            }
                        })
                        break;
                    case 'riskDotNeutral':
                        break;
                    default:
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        break;
                }
                break;
            //Kachelofen
            case 36:
                switch (riskName) {
                    case 'riskDotLow':
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        [37, 38].forEach(id => {
                            const index = numOfSelectedFeststellungen.indexOf(id);
                            if (index > -1) {

                                numOfSelectedFeststellungen.splice(index, 1);
                            }
                        })
                        break;
                    case 'riskDotNothing':
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        [37, 38, 39, 40, 41].forEach(id => {
                            const index = numOfSelectedFeststellungen.indexOf(id);
                            if (index > -1) {

                                numOfSelectedFeststellungen.splice(index, 1);
                            }
                        })
                        break;
                    case 'riskDotNeutral':
                        break;
                    default:
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        break;
                }
                break;
            //Cheminée
            case 42:
                switch (riskName) {
                    case 'riskDotLow':
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        [43, 44].forEach(id => {
                            const index = numOfSelectedFeststellungen.indexOf(id);
                            if (index > -1) {

                                numOfSelectedFeststellungen.splice(index, 1);
                            }
                        })
                        break;
                    case 'riskDotNothing':
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        [43, 44, 45, 46, 47].forEach(id => {
                            const index = numOfSelectedFeststellungen.indexOf(id);
                            if (index > -1) {

                                numOfSelectedFeststellungen.splice(index, 1);
                            }
                        })
                        break;
                    case 'riskDotNeutral':
                        break;
                    default:
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        break;
                }
                break;
            //Gasheizung
            case 48:
                switch (riskName) {
                    case 'riskDotLow':
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        [72].forEach(id => {
                            const index = numOfSelectedFeststellungen.indexOf(id);
                            if (index > -1) {

                                numOfSelectedFeststellungen.splice(index, 1);
                            }
                        })
                        break;
                    case 'riskDotNothing':
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        [72].forEach(id => {
                            const index = numOfSelectedFeststellungen.indexOf(id);
                            if (index > -1) {

                                numOfSelectedFeststellungen.splice(index, 1);
                            }
                        })
                        break;
                    case 'riskDotNeutral':
                        break;
                    default:
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        break;
                }
                break;
            //Ölheizung
            case 70:
                switch (riskName) {
                    case 'riskDotLow':
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        [64, 65].forEach(id => {
                            const index = numOfSelectedFeststellungen.indexOf(id);
                            if (index > -1) {

                                numOfSelectedFeststellungen.splice(index, 1);
                            }
                        })
                        break;
                    case 'riskDotNothing':
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        [64, 65, 66, 67, 68, 69].forEach(id => {
                            const index = numOfSelectedFeststellungen.indexOf(id);
                            if (index > -1) {

                                numOfSelectedFeststellungen.splice(index, 1);
                            }
                        })
                        break;
                    case 'riskDotNeutral':
                        break;
                    default:
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        break;
                }
                break;
            //Handfeuerlöscher (still a little buggy)
            case 51:
                switch (value?.feststellungName) {
                    case 'nicht vorhanden':
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        [52].forEach(id => {
                            const index = numOfSelectedFeststellungen.indexOf(id);
                            if (index > -1) {
                                numOfSelectedFeststellungen.splice(index, 1);
                            }
                        })
                        break;
                    case 'vorhanden, unvollst. oder falsch pos.':
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }

                        break;
                    default:
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        break;
                }
                break;
            //Dacheindeckung
            case 127:
                switch (riskName) {
                    case 'riskDotLow':
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        [1, 2].forEach(id => {
                            const index = numOfSelectedFeststellungen.indexOf(id);
                            if (index > -1) {

                                numOfSelectedFeststellungen.splice(index, 1);
                            }
                        })
                        break;
                    case 'riskDotMid':
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        [1, 2].forEach(id => {
                            const index = numOfSelectedFeststellungen.indexOf(id);
                            if (index > -1) {

                                numOfSelectedFeststellungen.splice(index, 1);
                            }
                        })
                        break;
                    case 'riskDotNeutral':
                        break;
                    default:
                        if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                            setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                        }
                        break;
                }
                break;
            default:
                if (riskName === "riskDotNeutral") {
                    //remove the entry from the array where numOfSelectedFeststellungen === deckung.idDeckungen
                    const index = numOfSelectedFeststellungen.indexOf(deckung.idDeckungen);
                    if (index > -1) {
                        numOfSelectedFeststellungen.splice(index, 1);
                    }
                } else if (numOfSelectedFeststellungen.indexOf(deckung.idDeckungen) === -1) {
                    setNumOfSelectedFeststellungen(numOfSelectedFeststellungen => [...numOfSelectedFeststellungen, deckung.idDeckungen])
                }
                break;
        }
    }

    return (
        <div>
            <DeckungenParent
                isUnterposition={isUnterposition}
                deckung={deckung}
                bemerkung={bemerkung}
                setBemerkung={setBemerkung}
                setChangesDone={setChangesDone}
                riskName={riskName}
                feststellungen={feststellungen}
                value={value?.feststellungName}
                handleSelect={handleSelect}
                updateFeststellungen={updateFeststellungen}
            />
        </div>
    )
}