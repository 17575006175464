import {Text, View} from "@react-pdf/renderer";
import React from "react";

export function Footer({styles, dataChecks, keyValue}) {
    return <View key={keyValue} fixed style={styles.footer}>
        <Text style={styles.footerText}
              render={() => (`glarnerSach – Gebäudecheck EFH – Objekt: ${dataChecks.strasse}, ${dataChecks.plz} ${dataChecks.ort}`)}/>
        <Text style={styles.pageNumber}
              render={({pageNumber, totalPages}) => (`${pageNumber} / ${totalPages}`)}/>
    </View>
}