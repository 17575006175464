const {Directus} = require('@directus/sdk');
export let appUrl = 'https://gebaeudecheck-be.serverraum.cloud/';
if (window.location.href.includes("localhost") || window.location.href.includes("efh-test")) {
    appUrl = 'https://gebaeudecheck-be-test.serverraum.cloud/';
}
const directus = new Directus(appUrl, {
    auth: {
        mode: 'json',
        autoRefresh: true,
        msRefreshBeforeExpires: 1000 * 60 * 5,
    },
});
export default directus;
