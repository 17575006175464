import {Navigate, useLocation} from "react-router-dom";
import {useState} from "react";

export function RequireAuth({children}) {
    let location = useLocation();
    const [authenticated] = useState(localStorage.getItem("auth_token") != null);

    if (!authenticated) {
        return <Navigate to="/login" state={{from: location}} replace/>;
    }

    return children;
}