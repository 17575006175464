import {Page, Text, View} from "@react-pdf/renderer";
import {styles} from "./styles/styles";
import {Footer} from "./footer";
import React from "react";
import {TocReference} from "./toc/tocReference";

export const statusIcon = {
    0: "riskSquareLow",
    1: "riskTriangleMid",
    2: "riskDotHigh"
}
export const branches = [
    {title: "Feuer", field: "feuerRisiko"},
    {title: "Elementar", field: "elementarRisiko"},
    {title: "Wasser", field: "wasserRisiko"},
    {title: "Einbruch", field: "einbruchRisiko"},
]

export function Summary({dataChecks}) {

    const statusText = [];
    statusText[0] = {
        0: <Text>Unsere optische Kontrolle hat ergeben, dass <Text style={styles.bold}> alles in Ordnung </Text>ist
            und kein Handlungsbedarf besteht.</Text>,
        1: <Text>Unsere optische Kontrolle hat <Text style={styles.bold}>kleinere Mängel</Text> aufgezeigt, welche
            jedoch Ihre Sicherheit nicht unmittelbar gefährden. Bitte beachten Sie unsere Hinweise dazu.</Text>,
        2: <Text>Unsere optische Kontrolle hat <Text style={styles.bold}>Mängel</Text> aufgezeigt, welche für Sie
            und Ihre Sicherheit eine Gefahr darstellen könnten und damit <Text
                style={styles.bold}>relevant</Text> sind. <Text style={styles.bold}>Bitte befolgen Sie unsere
                Hinweise.</Text></Text>
    };
    statusText[1] = {
        0: <Text>Unsere optische Kontrolle hat ergeben, dass <Text style={styles.bold}> alles in Ordnung </Text>ist
            und kein Handlungsbedarf besteht.</Text>,
        1: <Text>Unsere optische Kontrolle hat <Text style={styles.bold}>kleinere Mängel</Text> aufgezeigt, welche
            jedoch Ihre Sicherheit nicht unmittelbar gefährden. Bitte beachten Sie unsere Hinweise dazu.</Text>,
        2: <Text>Unsere optische Kontrolle hat <Text style={styles.bold}>Mängel</Text> aufgezeigt, welche für Sie
            und Ihre Sicherheit eine Gefahr darstellen könnten und damit <Text
                style={styles.bold}>relevant</Text> sind. <Text style={styles.bold}>Bitte befolgen Sie unsere
                Hinweise.</Text></Text>
    };
    statusText[2] = {
        0: <Text>Unsere optische Kontrolle hat ergeben, dass <Text style={styles.bold}> alles in Ordnung </Text>ist
            und kein Handlungsbedarf besteht.</Text>,
        1: <Text>Unsere optische Kontrolle hat <Text style={styles.bold}>kleinere Mängel</Text> aufgezeigt, welche
            jedoch für Haus und Personen keine unmittelbare Gefahr darstellen. Bitte beachten Sie unsere Hinweise
            dazu</Text>,
        2: <Text>Unsere optische Kontrolle hat ergeben, dass die <Text style={styles.bold}>festgestellten Mängel
            insbesondere für Ihr Haus eine Schadengefahr darstellen könnten. Bitte befolgen Sie unsere Hinweise.</Text></Text>
    };
    statusText[3] = {
        0: <Text>Unsere optische Kontrolle hat ergeben, dass Sie sich gegen Einbrüche massvoll abgesichert haben und
            kein unmittelbarer Handlungsbedarf besteht.</Text>,
        1: <Text>Unsere optische Kontrolle hat ergeben, dass Sie mit <Text style={styles.bold}>wenig Aufwand</Text> Ihre
            Sicherheit bezüglich Einbruch verbessern können. Bitte beachten Sie dazu unsere Hinweise</Text>,
        2: <Text>Unsere optische Kontrolle hat aufgezeigt, dass Ihr Gebäude <Text style={styles.bold}>relevante
            Mängel</Text> aufweist, welche Einbrechern den Zugang in Ihr Haus erleichtern. <Text style={styles.bold}>Bitte
            befolgen Sie unsere Hinweise.</Text></Text>
    };

    return <Page bookmark={{fit: true, title: "Zusammenfassung"}} key={"page_summary"} wrap={true} size="A4"
                 style={styles.page}>
        <Text key={`bookmark_sumamry}`} id={"bookmark_summary"} style={styles.section.header}>Zusammenfassung</Text>
        <TocReference key={"summary_ref"} id={"item2"}/>
        <Text key={"summary_text"} style={styles.textIntro}>Diese Übersicht gibt Ihnen einen ersten Eindruck über die
            Sicherheit in Ihrem Zuhause. Die detaillierten Beurteilungen und Verbesserungsvorschläge finden Sie im
            Bericht ab Seite 4.


        </Text>
        {branches.map((branche, index) => {
            if (dataChecks[branche.field] > 2) return null;
            return <View key={`summary_${index}`} style={styles.info_group}>
                <View key={`summary_view_${index}`} style={styles.text_normal}>
                    <View style={[styles.flex, {gap: 4, marginBottom: 4}]}>
                        <div style={[styles[statusIcon[dataChecks[branche.field]]] ?? styles.riskDotNeutral, {marginTop: 0}]}></div>
                        <Text style={styles.bolder}>{branche.title}</Text>
                    </View>
                    {statusText[index][dataChecks[branche.field]]}
                </View>
            </View>
        })}
        <Footer keyValue={"sumamry_footer"} styles={styles} dataChecks={dataChecks}/>
    </Page>
}