import {Link, Text, View} from "@react-pdf/renderer";
import {styles} from "../styles/styles";

export function AdviceElementar() {

    return <View wrap={false}>
        <Text style={styles.groupTitle}>Tipps</Text>
        <Text style={[styles.textFeststellung, styles.bold]}>Wetter-Alarm – die Schweizer Wetter App</Text>
        <Text style={styles.textFeststellung}>Der Wetter-Alarm liefert aktuelle Wetterdaten und -prognosen, Warnungen vor Unwettern,
            Livecams mit Panoramabildern und vieles mehr. Die glarnerSach unterstützt den Wetteralarm. Die App können
            Sie kostenlos herunterladen unter: <Link>www.wetteralarm.ch</Link></Text>
        <Text style={[styles.textFeststellung, styles.bold]}>Dach-Abonnement</Text>
        <Text style={styles.textFeststellung}>Verschiedene Fachfirmen (z.B. Dachdecker) bieten Dach-Abonnemente zur periodischen
            Prüfung und Reinigung an.</Text>
    </View>
}