import React, {Fragment} from "react";
import {StyleSheet, Text, View} from "@react-pdf/renderer";

const styles = StyleSheet.create({
    row: {
        flexDirection: "column",
        alignItems: "flex-start",
        padding: "4",
        borderBottom: "1px solid grey"
    },
    group_row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 2,
    },
    description: {
        width: {
            width: "70%"
        },
        title: {
            fontSize: 10,
            fontWeight: "bold",
        },
        subtitle: {
            fontSize: 9,
            fontWeight: "bold",
        },
        text: {
            fontSize: 9,
            fontWeight: "normal"
        }
    },
    extra_small: {
        fontSize: 8,
    },
    column: {
        width: "15%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    column_small1: {
        width: "5%",
        marginLeft: "6.2%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        textAlign: "left"
    },
    column_small2: {
        width: "7.5%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        textAlign: "left"
    },
    column_small3: {
        width: "10%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
        textAlign: "left"
    },
});

const TableRow = ({groups}) => {
    const rows = groups.map((group, sr) => {
        return <View key={`table_row_${sr.toString()}`} style={styles.row}>
            <Text style={styles.description.title}>{group.title}</Text>
            <View key={"row_" + sr.toString()}>
                {group.group.map((subGroup, index) => {
                    subGroup = subGroup.subgroup;
                    let groupID = subGroup.title.replace(" ", "_") + "_" + index.toString();
                    return <View key={`table_row_subGroup_${groupID}`} style={styles.group_row}>
                        <View key={`table_row_subGroup_${groupID}_title_group`} style={styles.description.width}>
                            <Text style={styles.description.subtitle}>{subGroup.title}</Text>
                            <Text style={styles.description.text}>{subGroup.text}</Text>
                        </View>
                        <View key={`table_row_subGroup_${groupID}_additional_info_group`}
                              style={subGroup.column2.additionalInfo ? styles.column_small1 : styles.column}>
                            <Text>{subGroup.column1.status ? "x" : ""}</Text>
                            {subGroup.column1.additionalInfo &&
                                <Text style={styles.extra_small}>{subGroup.column1.additionalInfo}</Text>}
                        </View>
                        {
                            subGroup.column2.additionalInfo &&
                            <View key={`table_row_subGroup_${groupID}_additional_info_group_2`}
                                  style={styles.column_small2}><Text
                                style={styles.extra_small}>{subGroup.column2.additionalInfo}</Text></View>
                        }
                        <View key={`table_row_subGroup_${groupID}_additional_info_group_3`}
                              style={subGroup.column2.additionalInfo ? styles.column_small3 : styles.column}>
                            <Text>{subGroup.column3.status ? "x" : ""}</Text>
                            {subGroup.column3.additionalInfo &&
                                <Text style={styles.extra_small}>{subGroup.column1.additionalInfo}</Text>}
                        </View>
                    </View>
                })}
            </View>
        </View>
    })
    return <Fragment>{rows}</Fragment>;
};

export default TableRow;