import {styles} from "./styles/styles";
import {Page, Text, View} from "@react-pdf/renderer";
import {AdviceFire} from "./texts/advice_fire";
import {AdviceElementar} from "./texts/advice_elementar";
import {TocReference} from "./toc/tocReference";
import React from "react";
import {Group} from "./group";
import {GroupSpecial} from "./groupSpecial";
import {modules} from "../utils/constants";
import {GroupElementar} from "./groupElementar";
import {Footer} from "./footer";

export function ModulCheck({module, branchID, order, checkID, dataChecks}) {
    const printListOfModules = {
        'fire': [
            {moduleID: [modules.FEUER]}, //Holzfeuerung
            {moduleID: [modules.LAGERUNG_BRENNMATERIALIEN]}, //Lagerung Brennmaterialien
            {moduleID: [modules.FEUER], includeIds: [28, 34, 40, 46]}, ////Abstände zu Brennbarem
            {moduleID: [modules.FEUER], includeIds: [27, 33, 39, 45]}, //Pflichtabstände
            {moduleID: [modules.OLHEIZUNG], includeIds: [70, 64, 65]}, //ölheizung
            {
                moduleID: [modules.OLHEIZUNG],
                includeIds: [66, 68, 67, 69],
                showModuleNameInsteadOfDeckung: true,
                overwrittenTitle: "Öltank"
            }, //öltank
            {moduleID: [modules.GASHEIZUNG], showModuleNameInsteadOfDeckung: true}, //Gasheizung
            {
                moduleID: [modules.KAMINFEGER, modules.FEUER],
                includeIds: [50, 25, 31, 37, 43, 64],
                showModuleNameInsteadOfDeckung: true
            }, //kaminfeger
            {moduleID: [modules.BILTZSCHUTZ]}, //äusserer Blitzschutz
            {moduleID: [modules.HANDFEUERLOSCHER]}, //Handfeuerlöscher
            {moduleID: [modules.RAUCHMELDER]}, //Rauchmelder
            {moduleID: [modules.PRAVENTIONSARTIKEL]}, //Präventionsartikel
        ],
        'elementar': [
            {moduleID: [modules.DACHEINDECKUNG]}, //Dacheindeckung
            {moduleID: [modules.HOCHWASSER]}, //Hochwaser
            {moduleID: [modules.UEBERSCHWEMMUNG]}, //Überschwemmung
            {moduleID: [modules.SONNENSTOREN]}, //elektronische Sonnenstoren
        ],
        'water': [
            {moduleID: [modules.FASSADE]}, //Fassade
            {moduleID: [modules.KELLER]}, //keller
            {moduleID: [modules.LECK]}, //Leck puck
        ],
        "burglary": [
            {moduleID: [modules.AUSSENBELEUCHTUNG]}, //Aussenbeleuchtung
            {moduleID: [modules.FENSTER]}, //Fenster
        ]
    };

    let header;
    let advice;
    switch (module) {
        case 'fire':
            header = "Feuer";
            advice = <AdviceFire/>;
            break;
        case 'elementar':
            header = "Elementar";
            advice = <AdviceElementar/>;
            break;
        case 'water':
            header = "Wasser";
            break;
        case 'burglary':
            header = "Einbruch";
            break;
        default:
            return <></>;
    }

    return <Page bookmark={{fit: true, title: "Gebäudecheck EFH " + header}} style={styles.page} wrap
                 key={`module_${module}`}>
        <View wrap={true}>
            <Text id={"bookmark_" + module} style={styles.section.header}>Gebäudecheck EFH {header}</Text>
            <TocReference key={module + "_ref"} id={`item${order}`}/>
            <View>
                {printListOfModules[module]?.map((item, index) => {
                        if (item.includeIds) {
                            return <GroupSpecial branchID={branchID} module={module}
                                                 moduleID={item.moduleID} checkID={checkID}
                                                 includeIds={item.includeIds}
                                                 showModuleNameInsteadOfDeckung={item.showModuleNameInsteadOfDeckung ?? false}
                                                 overwrittenTitle={item.overwrittenTitle ?? null}
                            />;
                        }
                        if (module !== "fire") {
                            return <GroupElementar branchID={branchID} module={module} moduleID={item.moduleID}
                                                   checkID={checkID}
                                                   showModuleNameInsteadOfDeckung={item.showModuleNameInsteadOfDeckung ?? false}/>
                        }
                        return <Group branchID={branchID} module={module} moduleID={item.moduleID} checkID={checkID}
                                      showModuleNameInsteadOfDeckung={item.showModuleNameInsteadOfDeckung ?? false}/>
                    }
                )}
            </View>
            {advice}
        </View>
        <Footer keyValue={`module_check_footer_${module}`} styles={styles} dataChecks={dataChecks}/>
    </Page>
}