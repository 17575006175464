import {PuffLoader} from "react-spinners";
import React from "react";

export function LoadingAnimation() {
    return <PuffLoader
        color={"#634a12"}
        loading={true}
        size={100}
        aria-label="Loading Spinner"
        data-testid="loader"
        cssOverride={{position: "fixed", top: "calc(50vh - 50px)", left: "calc(50vw - 50px)"}}
    />
}