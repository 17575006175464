import React, {useEffect, useState} from 'react'
import directus from '../lib/directus';
import {Button, Dropdown, DropdownButton, Form, InputGroup, Row} from 'react-bootstrap'
import moment from 'moment';
import CustomDropdown from './dropdown';
import {useNavigate} from 'react-router-dom';
import {toast} from "react-toastify";
import {useAtom} from "jotai";
import {atomCheckID} from "./uebersicht";
import {atomChangesDone} from "../App";

async function getObjekt(id) {
    const {data} = await directus.items('checks').readByQuery({
        filter: {
            idChecks: id,
        },
    });
    return data;
}

function Details({createNew}) {
    const [idCheck, setIdCheck] = useAtom(atomCheckID);
    const checks = directus.items('checks');
    const [objekte, setObjekte] = useState([{}]);
    const [changesDone, setChangesDone] = useAtom(atomChangesDone);
    const [userInput, setUserInput] = useState({
        0: {
            datumErstellt: '',
            fkPBerater: '',
            fkVBerater: '',
            kommentar: '',
            objektCode: '',
            ort: "",
            ortsCode: '',
            plz: '',
            primEigentuemer: '',
            primEigentuemerGeschlecht: '',
            primEigentuemerNName: '',
            secEigentuemer: '',
            secEigentuemerGeschlecht: '',
            secEigentuemerNName: '',
            status: '',
            strasse: '',
        }
    });
    const placeholder = '';
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            if (idCheck) {

                const result = await getObjekt(idCheck);

                setUserInput(result);
                setObjekte(result);
            }

        }

        fetchData();
    }, [idCheck]);

    const updateObject = async () => {
        await checks.updateOne(idCheck, {
            datumErstellt: userInput[0].datumErstellt,
            fkPBerater: userInput[0].fkPBerater,
            fkVBerater: userInput[0].fkVBerater,
            idChecks: userInput[0].idChecks,
            kommentar: userInput[0].kommentar,
            objektCode: userInput[0].objektCode,
            ort: userInput[0].ort,
            ortsCode: userInput[0].ortsCode,
            plz: userInput[0].plz,
            primEigentuemer: userInput[0].primEigentuemer,
            primEigentuemerGeschlecht: userInput[0].primEigentuemerGeschlecht,
            primEigentuemerNName: userInput[0].primEigentuemerNName,
            secEigentuemer: userInput[0].secEigentuemer,
            secEigentuemerGeschlecht: userInput[0].secEigentuemerGeschlecht,
            secEigentuemerNName: userInput[0].secEigentuemerNName,
            status: userInput[0].status,
            strasse: userInput[0].strasse,
        }).then(() => {
            toast.success('Erfolgreich gespeichert');
            setChangesDone(false);
        }).catch((error) => {
            toast.error('Fehler beim Speichern');
            setChangesDone(false);
        });

        async function fetchData() {
            if (idCheck) {
                const result = await getObjekt(idCheck);
                setObjekte(result);
                setUserInput(result);
            }
        }

        fetchData();
    };

    const createObject = async () => {
        await checks.createOne({
            datumErstellt: userInput[0].datumErstellt ?? moment().format('YYYY-MM-DD'),
            fkPBerater: userInput[0].fkPBerater,
            fkVBerater: userInput[0].fkVBerater,
            kommentar: userInput[0].kommentar,
            objektCode: userInput[0].objektCode,
            ort: userInput[0].ort,
            ortsCode: userInput[0].ortsCode,
            plz: userInput[0].plz,
            primEigentuemer: userInput[0].primEigentuemer,
            primEigentuemerGeschlecht: userInput[0].primEigentuemerGeschlecht,
            primEigentuemerNName: userInput[0].primEigentuemerNName,
            secEigentuemer: userInput[0].secEigentuemer,
            secEigentuemerGeschlecht: userInput[0].secEigentuemerGeschlecht,
            secEigentuemerNName: userInput[0].secEigentuemerNName,
            status: userInput[0].status,
            strasse: userInput[0].strasse,
            feuerRisiko: 4,
            wasserRisiko: 4,
            elementarRisiko: 4,
            einbruchRisiko: 4,
        }).then((response) => {
            setIdCheck(response.idChecks);
            setChangesDone(false)
            toast.success('Erfolgreich erstellt');

        }).catch((error) => {
            toast.error('Fehler beim Erstellen');
        });
    };


    const dateForPicker = (dateString) => {
        return moment(new Date(dateString)).format('YYYY-MM-DD')
    };

    const handleChange = evt => {
        const name = evt.target.name;
        let value = evt.target.value;
        if (name === "ortsCode" && value.length > 3) value = value.substring(0, 3);
        if (name === "objektCode" && value.length > 3) value = value.substring(0, 5);
        if (name === "plz" && value.length > 3) value = value.substring(0, 4);
        setUserInput(prevState => ({
            ...prevState,
            0: {
                ...prevState[0],
                [name]: value
            }
        }))
        setChangesDone(true);
    }

    const handleDropdownChange = evt => {
        const name = evt.target.name;
        const value = evt.target.getAttribute('value');
        setChangesDone(true);
        setUserInput(prevState => ({
            ...prevState,
            0: {
                ...prevState[0],
                [name]: JSON.parse(value)
            }
        }))
    }

    const resetButton = () => {
        setUserInput(objekte)
        setChangesDone(false);
    }

    const createOrSafeCheck = (e) => {
        e.preventDefault();
        let isValid = true;
        if (!userInput[0].fkPBerater || !userInput[0].fkVBerater) {
            toast.warning('Bitte Berater auswählen');
            isValid = false;
        }
        if (userInput[0].ortsCode.length !== 3) {
            toast.warning("Gemeinde muss 3 Zeichen lang sein.");
            isValid = false;
        }
        if (userInput[0].objektCode.length > 5) {
            toast.warning("Gebäudenummer muss zwischen 1 und 5 Zeichen lang sein.")
            isValid = false;
        }
        if (userInput[0].plz.length !== 4 || isNaN(parseInt(userInput[0].plz))) {
            toast.warning("PLZ muss 4 Zeichen lang sein und Nummerisch sein.")
            isValid = false;
        }
        if (!userInput[0].primEigentuemerGeschlecht) {
            toast.warning("Bitte Geschlecht des Primären Eigentümers auswählen.")
            isValid = false;
        }
        if ((userInput[0].secEigentuemer !== "" || userInput[0].secEigentuemerNName !== "") && (userInput[0].secEigentuemerGeschlecht === "" || userInput[0].secEigentuemerNName === "" || userInput[0].secEigentuemer === "")) {
            toast.warning("Sekundären Eigentümers unvollständig.")
            isValid = false;
        }
        if (createNew && isValid) {
            if (isValid) {
                createObject();
            }
        } else {
            if (isValid) {
                updateObject();
            }
        }
    }

    const deleteButton = async () => {
        //ask with an alert if the user is sure to delete the check
        const confirm = window.confirm('Sind Sie sicher, dass Sie diesen Gebäude-Check löschen möchten?');
        if (confirm) {
            //using directus delete all auswertungen with the fkCheck = idCheck
            await directus.items('auswertungen').readByQuery({
                fields: "idAuswertungen",
                filter: {
                    fkCheck: {
                        _eq: idCheck
                    }
                }
            }).then(async (response) => {
                if (response.data.length > 0) {
                    const ids = [];
                    response.data.forEach((item) => {
                        ids.push(item.idAuswertungen);
                    });
                    await directus.items('auswertungen').deleteMany(ids).then(() => {
                        toast.success('Auswertungen erfolgreich gelöscht');
                    }).catch((error) => {
                        toast.error('Fehler beim Löschen der Auswertungen');
                        return error;
                    });
                }
            }).then(() => {
                //delete the check
                checks.deleteOne(idCheck).then(() => {
                    toast.success('Gebäude-Check erfolgreich gelöscht');
                    navigate("/uebersicht");
                });
            }).catch((error) => {
                toast.error('Fehler beim Löschen des Gebäude-Checks');
                return error;
            });
        }
    }

    return (
        <div
            style={{border: '1px solid', borderColor: 'rgba(108, 117, 125, .5)', borderRadius: '5px', padding: '20px'}}>
            <h2>Gebäudecheck - EFH</h2>
            <form onSubmit={createOrSafeCheck} className="mt-3">
                <Row className="mb-1">
                    <Form.Group controlId="formBasicLagerbuch" className="col col-sm-4">
                        <Form.Label>Lagerbuchnummer *</Form.Label>
                        <InputGroup>
                            <Form.Control
                                placeholder='Gemeinde'
                                aria-label="Lagerbuchnummer Gemeinde"
                                type="lagerbuch"
                                aria-describedby="basic-addon1"
                                style={{maxWidth: '20%'}}
                                className="form-control"
                                name="ortsCode"
                                value={userInput[0] ? (userInput[0].ortsCode ? userInput[0].ortsCode.substring(0, 3) : placeholder) : placeholder}
                                onChange={handleChange}
                                isValid={userInput[0]?.ortsCode?.length === 3}
                                required
                            />
                            <Form.Text style={{margin: '5px 5px'}}>-</Form.Text>
                            <Form.Control
                                placeholder='Gebäudenummer'
                                aria-label="Lagerbuchnummer Gebäudenummer"
                                type="lagerbuch"
                                aria-describedby="basic-addon1"
                                className="form-control"
                                name="objektCode"
                                value={userInput[0] ? (userInput[0].objektCode ? userInput[0].objektCode.substring(0, 5) : placeholder) : placeholder}
                                onChange={handleChange}
                                isValid={userInput[0]?.objektCode?.length <= 5}
                                required
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group controlId="formBasicDatum" className="col col-sm-2">
                        <Form.Label>Datum Gebäudecheck*</Form.Label>
                        <Form.Control
                            type="date"
                            name="datumErstellt"
                            isValid={moment(userInput[0]?.datumErstellt).isValid()}
                            value={dateForPicker(userInput[0] ? (userInput[0].datumErstellt ? userInput[0].datumErstellt : placeholder) : placeholder)}
                            onChange={handleChange}
                            className="form-control"
                            required
                        />
                    </Form.Group>
                    <Form.Group controlId='formBasicStatus' className='col'>
                        <Form.Label>Status</Form.Label>
                        <DropdownButton
                            title={userInput[0] ? (userInput[0].status ? userInput[0].status : '- bitte wählen -') : '- bitte wählen -'}
                            variant='secondary' className='btn-block'>
                            <Dropdown.Item name="status" value={JSON.stringify('In Bearbeitung')}
                                           onClick={handleDropdownChange}>In Bearbeitung</Dropdown.Item>
                            <Dropdown.Item name="status" value={JSON.stringify('Nachfassen')}
                                           onClick={handleDropdownChange}>Nachfassen</Dropdown.Item>
                            <Dropdown.Item name="status" value={JSON.stringify('Abgeschlossen')}
                                           onClick={handleDropdownChange}>Abgeschlossen</Dropdown.Item>
                            <Dropdown.Item name="status" value={JSON.stringify('Abgebrochen')}
                                           onClick={handleDropdownChange}>Abgebrochen</Dropdown.Item>
                        </DropdownButton>
                    </Form.Group>
                </Row>
                <Row className="mb-1">
                    <Form.Group className=" col col-sm-4" controlId="Adresse">
                        <Form.Label>Adresse*</Form.Label>
                        <Form.Control
                            placeholder='Adresse'
                            className="form-control"
                            type="text"
                            name="strasse"
                            value={userInput[0] ? (userInput[0].strasse ? userInput[0].strasse : placeholder) : placeholder}
                            isValid={userInput[0]?.strasse?.length > 0}
                            onChange={handleChange}
                            required
                        />

                        <Form.Label>PLZ*</Form.Label>
                        <Form.Control
                            placeholder='PLZ'
                            className="form-control"
                            type="text"
                            name="plz"
                            value={userInput[0] ? (userInput[0].plz ? userInput[0].plz : placeholder) : placeholder}
                            isValid={userInput[0]?.plz?.length === 4}
                            onChange={handleChange}
                            required
                        />

                    </Form.Group>
                    <Form.Group className=" col col-sm-4" controlId="Versicherungsnehmer1">
                        <Form.Label>Versicherungsnehmer 1</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Select
                                required
                                className="form-control"
                                style={{width: '25%'}}
                                name="primEigentuemerGeschlecht"
                                aria-label="Default select example"
                                value={userInput[0] ? (userInput[0].primEigentuemerGeschlecht ? userInput[0].primEigentuemerGeschlecht : placeholder) : placeholder}
                                isValid={userInput[0]?.primEigentuemerGeschlecht?.trim() !== "" && userInput[0].primEigentuemerGeschlecht !== "Bitte wählen" && userInput[0].primEigentuemerGeschlecht?.length > 0}
                                onChange={handleChange}>
                                <option value={""}>Bitte wählen</option>
                                <option value="Herr">Herr</option>
                                <option value="Frau">Frau</option>
                            </Form.Select>
                            <div style={{margin: '5px 5px'}}></div>
                            <Form.Control
                                required
                                placeholder='Vorname'
                                className="form-control"
                                style={{width: '70%'}}
                                type="text"
                                name="primEigentuemer"
                                isValid={userInput[0]?.primEigentuemer?.length > 0}
                                value={userInput[0] ? (userInput[0].primEigentuemer ? userInput[0].primEigentuemer : placeholder) : placeholder}
                                onChange={handleChange}
                            />
                        </InputGroup>
                        <InputGroup>
                            <Form.Control
                                required
                                placeholder='Nachname'
                                className="form-control"
                                style={{width: '100%'}}
                                type="text"
                                name="primEigentuemerNName"
                                value={userInput[0] ? (userInput[0].primEigentuemerNName ? userInput[0].primEigentuemerNName : placeholder) : placeholder}
                                isValid={userInput[0]?.primEigentuemerNName?.length > 0}
                                onChange={handleChange}
                            />
                        </InputGroup>
                    </Form.Group>
                    <Form.Group className=" col col-sm-4" controlId="Versicherungsnehmer2">
                        <Form.Label>Versicherungsnehmer 2</Form.Label>
                        <InputGroup className="mb-3">
                            <Form.Select
                                className="form-control"
                                style={{width: '25%'}}
                                name="secEigentuemerGeschlecht"
                                aria-label="Default select example"
                                value={userInput[0] ? (userInput[0].secEigentuemerGeschlecht ? userInput[0].secEigentuemerGeschlecht : placeholder) : placeholder}
                                isValid={userInput[0]?.secEigentuemerGeschlecht?.trim() !== "" && userInput[0].secEigentuemerGeschlecht !== "Bitte wählen" && userInput[0].secEigentuemerGeschlecht?.length > 0}
                                onChange={handleChange}
                            >
                                <option value={""}>Bitte wählen</option>
                                <option value="Herr">Herr</option>
                                <option value="Frau">Frau</option>
                            </Form.Select>
                            <div style={{margin: '5px 5px'}}></div>
                            <Form.Control
                                placeholder='Vorname'
                                className="form-control"
                                style={{width: '70%'}}
                                type="text"
                                name="secEigentuemer"
                                value={userInput[0] ? (userInput[0].secEigentuemer ? userInput[0].secEigentuemer : placeholder) : placeholder}
                                isValid={userInput[0]?.secEigentuemer?.length > 0}
                                onChange={handleChange}
                            />
                        </InputGroup>
                        <InputGroup>
                            <Form.Control
                                placeholder='Nachname'
                                className="form-control"
                                style={{width: '45%'}}
                                type="text"
                                name="secEigentuemerNName"
                                value={userInput[0] ? (userInput[0].secEigentuemerNName ? userInput[0].secEigentuemerNName : placeholder) : placeholder}
                                isValid={userInput[0]?.secEigentuemerNName?.length > 0}
                                onChange={handleChange}
                            />
                        </InputGroup>
                    </Form.Group>
                </Row>
                <Row className="mb-1">
                    <Form.Group className=" col col-sm-4" controlId="Ort">
                        <Form.Label>Ort*</Form.Label>
                        <Form.Control
                            placeholder='Ort'
                            className="form-control"
                            type="text"
                            name="ort"
                            value={userInput[0] ? (userInput[0].ort ? userInput[0].ort : placeholder) : placeholder}
                            isValid={userInput[0]?.ort?.length > 0}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>
                    <Form.Group className=" col col-sm-4" controlId="Versicherungsnehmer1" required>
                        <Form.Label>Versicherungsberater*</Form.Label>
                        <CustomDropdown gespeicherterBerater={userInput[0] ? (userInput[0].fkVBerater) : placeholder}
                                        isVBerater={1} handleChange={handleDropdownChange}/>
                    </Form.Group>
                    <Form.Group className=" col col-sm-4" controlId="Versicherungsnehmer1" required>
                        <Form.Label>Präventionsexperte*</Form.Label>
                        <CustomDropdown gespeicherterBerater={userInput[0] ? (userInput[0].fkPBerater) : placeholder}
                                        isVBerater={0} handleChange={handleDropdownChange}/>
                    </Form.Group>
                </Row>
                <Row className="mb-3">
                    <Form.Group className=" col col-sm-12" controlId="bemerkung">
                        <Form.Label>Bemerkung</Form.Label>
                        <Form.Control
                            placeholder='Bemerkung'
                            className="form-control"
                            type="text"
                            name="kommentar"
                            as="textarea" rows="2"
                            value={userInput[0] ? (userInput[0].kommentar ? userInput[0].kommentar : placeholder) : placeholder}
                            isValid={userInput[0]?.kommentar?.length > 0}
                            onChange={handleChange}
                        />
                    </Form.Group>
                </Row>
                <Row>
                    <Form.Group className="col" controlId="Buttons">
                        <Button className='mr-4' style={{
                            background: !changesDone ? "#ccc" : "#007bff",
                            borderColor: !changesDone ? "#ccc" : "#007bff"
                        }} type='submit' disabled={!changesDone} variant="secondary">Speichern</Button>
                        <Button disabled={!changesDone} type="reset" variant="secondary" className='mr-4'
                                onClick={resetButton}>Reset</Button>
                        {idCheck && <Button type="reset" variant="danger" onClick={deleteButton}>Löschen</Button>}
                    </Form.Group>
                </Row>
            </form>
        </div>
    )
}

export default Details;