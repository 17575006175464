export const deckungen = {
    "ABSTAND_ZU_BRENNBAREM_HOLZHEIZUNG": 28,
    "ABSTAND_ZU_BRENNBAREM_HOLZHERD": 28,
    "ABSTAND_ZU_BRENNBAREM_KACHELOFEN": 28,
    "ABSTAND_ZU_BRENNBAREM_CHEMINEE": 28,
}
export const modules = {
    "FEUER": 9,
    "LAGERUNG_BRENNMATERIALIEN": 11,
    "OLHEIZUNG": 12,
    "GASHEIZUNG": 2,
    "KAMINFEGER": 10,
    "BILTZSCHUTZ": 18,
    "HANDFEUERLOSCHER": 7,
    "RAUCHMELDER": 15,
    "PRAVENTIONSARTIKEL": 14,
    "DACHEINDECKUNG": 1,
    "HOCHWASSER": 5,
    "UEBERSCHWEMMUNG": 6,
    "SONNENSTOREN": 8,
    "FASSADE": 3,
    "KELLER": 4,
    "LECK": 13,
    "AUSSENBELEUCHTUNG": 16,
    "FENSTER": 17,
}