import { useEffect, useState } from "react";
import Deckungen, { atomRisk } from "./Deckungen";
import { atom, useAtom } from "jotai";
import { atomTriggerStatus } from "../checks";

export const atomFeststellungenValue = atom([]);

export default function ModulBody({
    updateData,
    deckungen,
    setNumOfFeststellungen,
    setNumOfDeckungen,
    setListOfRisks,
}) {
    const [numOfSelectedFeststellungen, setNumOfSelectedFeststellungen] = useState([]);
    const [feststellungsValue, setFeststellungsValue] = useAtom(atomFeststellungenValue);
    const [triggerStatus,] = useAtom(atomTriggerStatus);
    const [, setRisk] = useAtom(atomRisk);

    useEffect(() => {
        let count = 0;
        deckungen.forEach((deckung) => {
            let triggerIsSet = false;
            for (const status of triggerStatus) {
                for (const feststellung of feststellungsValue) {
                    if (
                        deckung?.abhaengigkeit === feststellung?.abhaengigkeit &&
                        status?.statusNr === feststellung?.feststellung?.status
                    ) {
                        if (
                            status.fkDeckung === deckung.idDeckungen &&
                            feststellung?.feststellung.feststellungName !== 'nicht vorhanden'
                        ) {
                            triggerIsSet = true;
                        }
                    }
                }
            }
            if (deckung.versteckt === 0 || triggerIsSet) {
                count++;
            }
        });
        setNumOfDeckungen(count);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [deckungen, triggerStatus, feststellungsValue]);

    return (
        <div>
            {
                deckungen
                    .sort(function (a, b) {
                        return a.reihenfolgeGui - b.reihenfolgeGui
                    })
                    .map((deckung, i) => {
                        let triggerIsSet = false;
                        for (const status of triggerStatus) {
                            for (const feststellung of feststellungsValue) {
                                if (deckung?.abhaengigkeit === feststellung?.abhaengigkeit && status?.statusNr === feststellung?.feststellung?.status) {
                                    if (status?.fkDeckung === deckung.idDeckungen && feststellung?.feststellung.feststellungName !== 'nicht vorhanden') {
                                        if (feststellung?.versteckt === 0)
                                            triggerIsSet = true;
                                    }
                                }
                            }
                        }
                        if (
                            deckung.versteckt === 0
                            || triggerIsSet
                        ) {
                            if (deckung.versteckt === 0) {
                                return (
                                    <Deckungen
                                        triggerStatus={triggerStatus}
                                        isUnterposition={0}
                                        key={i}
                                        i={deckung?.idDeckungen}
                                        deckung={deckung}
                                        updateData={updateData}
                                        setNumOfSelectedFeststellungen={setNumOfSelectedFeststellungen}
                                        numOfSelectedFeststellungen={numOfSelectedFeststellungen}
                                        setFeststellungsValue={setFeststellungsValue}
                                        feststellungsValue={feststellungsValue}
                                        setListOfRisks={setListOfRisks}
                                        setNumOfFeststellungen={setNumOfFeststellungen}
                                    />
                                )
                            } else {
                                return (
                                    <Deckungen
                                        triggerStatus={triggerStatus}
                                        isUnterposition={1}
                                        key={i}
                                        i={deckung?.idDeckungen}
                                        deckung={deckung}
                                        updateData={updateData}
                                        setNumOfSelectedFeststellungen={setNumOfSelectedFeststellungen}
                                        numOfSelectedFeststellungen={numOfSelectedFeststellungen}
                                        setFeststellungsValue={setFeststellungsValue}
                                        feststellungsValue={feststellungsValue}
                                        setListOfRisks={setListOfRisks}
                                        setNumOfFeststellungen={setNumOfFeststellungen}
                                    />
                                )
                            }
                        } else {
                            // delete feststellungenValue and risk of unterdeckungen when they are hidden
                            for (const feststellung of feststellungsValue) {
                                if (feststellung.idDeckung === deckung.idDeckungen) {
                                    setFeststellungsValue(feststellungsValue.filter(entry => entry !== feststellung))
                                    setRisk(risk => risk.filter(entry => entry.fkDeckung !== deckung.idDeckungen))
                                }
                            }
                        }
                        return null
                    })
            }
        </div>
    )

}