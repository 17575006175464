import Checks from '../checks'
import Details from '../details'
import React, {useEffect} from 'react';
import {useAtom} from "jotai";
import {atomCheckID} from "../uebersicht";

const excludeKeys = ["auth_token", "auth_refresh_token", "auth_expires", "auth_expires_at"]

export function Allgemein() {
    const [idCheck] = useAtom(atomCheckID);
    useEffect(() => {
        if (!idCheck) {
            Object.keys(localStorage).forEach(key => {
                if (excludeKeys.includes(key)) return;
                localStorage.removeItem(key);
            })
        }
    }, [idCheck]);

    return (
        <div className='mb-3'>
            <Details idChecks={idCheck} createNew={!idCheck}/>
            <div className='mb-3'></div>
            {idCheck && <Checks idChecks={idCheck}/>}
        </div>
    )
}
