import React, {useState} from "react";
import directus from "../../lib/directus";
import {useNavigate} from "react-router-dom";
import {useAtom} from "jotai";
import {atomAuthenticated} from "../../App";

export function LoginForm({props, history}) {
    const [errorMessage, setErrorMessage] = useState("");
    const [authenticated, setAuthenticated] = useAtom(atomAuthenticated);
    const navigate = useNavigate();

    const showErrorMessage = (message) => {
        setErrorMessage(message);
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        const {uname, pass} = document.forms[0];

        if (uname.value && pass.value) {
            directus.auth.login({email: uname.value, password: pass.value}).then(response => {
                if (response.access_token) {
                    setAuthenticated(true)
                    setTimeout(() => {
                        navigate("/uebersicht", {replace: true})
                    }, 500)
                } else {
                    showErrorMessage(response.message);
                }
            }).catch((response) => {
                showErrorMessage(response.message);
            });
        }
    };

    if (authenticated) {
        return <></>
    }

    return (
        <div className='loginForm'>
            <form onSubmit={handleSubmit}>
                <div className="input-container">
                    <label>Benutzername </label>
                    <input placeholder={"Benutzername"} type="text" name="uname" required/>
                </div>
                <div className="input-container">
                    <label>Passwort </label>
                    <input placeholder={"Passwort"} type="password" name="pass" required/>
                </div>
                <div className="button-container">
                    <input type="submit" value={"Anmelden"}/>
                </div>
            </form>
            {errorMessage && <div className={"error-message"}>{errorMessage}</div>}
        </div>
    );
}
