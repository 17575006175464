import React from 'react';
import {Document} from '@react-pdf/renderer';
import {Summary} from "./summary";
import {ModulCheck} from "./modulCheck";
import {Cover} from "./cover";
import {Intro} from "./intro";
import {ProvidePageReferences} from "./toc/tocReference";
import {InsuranceOverview} from "./insuranceOverview";

export const Template = (props) => {
    const {dataChecks, idChecks, fileName} = props;
    const date = new Date(dataChecks.datumErstellt);
    const dateString = new Intl.DateTimeFormat('de-de', {day: "2-digit", month: "long", year: "numeric"}).format(date);
    const nowDateString = new Intl.DateTimeFormat('de-de', {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
    }).format(new Date());

    return <Document title={fileName}>
        <ProvidePageReferences>
            <Cover dataChecks={dataChecks} dateString={dateString} nowDateString={nowDateString}/>
            <Intro dataChecks={dataChecks}/>
            <Summary dataChecks={dataChecks}/>
            {dataChecks.feuerRisiko < 3 &&
                <ModulCheck module={"fire"} branchID={1} order={3} checkID={idChecks} dataChecks={dataChecks}/>}
            {dataChecks.elementarRisiko < 3 &&
                <ModulCheck module={"elementar"} branchID={3} order={4} checkID={idChecks} dataChecks={dataChecks}/>}
            {dataChecks.wasserRisiko < 3 &&
                <ModulCheck module={"water"} branchID={2} order={5} checkID={idChecks} dataChecks={dataChecks}/>}
            {dataChecks.einbruchRisiko < 3 &&
                <ModulCheck module={"burglary"} branchID={4} order={6} checkID={idChecks} dataChecks={dataChecks}/>}
            <InsuranceOverview dataChecks={dataChecks}/>
        </ProvidePageReferences>
    </Document>
};