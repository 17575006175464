import {styles} from "./styles/styles";
import {Image, Page, Text, View} from "@react-pdf/renderer";
import React from "react";
import {Toc} from "./toc/toc";
import {appUrl} from "../../lib/directus";

export function Cover({dataChecks, dateString, nowDateString}) {
    return <Page wrap={true} size="A4" style={styles.cover_page}>
        {/*<View style={styles.header}>*/}
        <Image style={styles.header}
               src={`${appUrl}/assets/873d2924-5f94-4596-966e-8c8c24bc16a7`}/>}
        <Text style={styles.headline1}>
            Bericht
        </Text>
        <Text style={styles.headline2}>
            Gebäudecheck EFH
        </Text>
        {dataChecks.object_image && <Image
            style={styles.objectImage}
            src={`${appUrl}/assets/${dataChecks.object_image?.id}`}
        />}
        {!dataChecks.object_image && <View
            style={styles.objectImage}
        />}
        <Text style={styles.objectText}>
            Objekt: {dataChecks.strasse}, {dataChecks.plz} {dataChecks.ort}
        </Text>
        <Text style={styles.objectTextSmall}
              render={() => `Der Gebäudecheck EFH wurde am ${dateString} durchgeführt.`}/>

        <Toc dataChecks={dataChecks}/>
        <Text>Glarus, {nowDateString}</Text>
        <View fixed style={[styles.footer, {border: "none", left: "1.5cm", right: "1.5cm"}]}>
            {/*<Text style={styles.footerTextPage1} render={() => (`Das Sicherheitssystem der Kantonalen Sachversicherung Glarus`)}/>*/}
            <Image style={styles.footerTextPage1} src={`${appUrl}/assets/0c84b7b5-9a2b-489f-9f94-fcb99e7ab5bb`}/>
        </View>
    </Page>
}