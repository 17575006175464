import {createContext, useContext, useRef} from "react";
import {View} from "@react-pdf/renderer";

const Context = createContext();

export function ProvidePageReferences({children}) {
    const ref = useRef();
    if (!ref.current) {
        ref.current = new Map();
    }
    return <Context.Provider value={ref.current}>{children}</Context.Provider>;
}

export function usePageReferences() {
    return useContext(Context);
}

export function TocReference({id}) {
    const references = usePageReferences();
    return references && id ? (
        <View
            key={"ref_" + id}
            wrap={false}
            render={({pageNumber}) => {
                references.set(id, pageNumber);
            }}
        />
    ) : null;
}