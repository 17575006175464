export const tableData = [
    {
        group: [
            {
                subgroup: {
                    title: "Feuer", text: "Brand, Rauch, Blitzschlag, Explosion usw.",
                    column1: {
                        status: true,
                        additionalInfo: "(obligatorisch)"
                    },
                    column2: {
                        additionalInfo: ""
                    },
                    column3: {
                        status: true,
                        additionalInfo: ""
                    }
                }
            },
        ],
    },
    {
        group: [
            {
                subgroup:
                    {
                        title: "Elementar",
                        text: "Hochwasser, Überschwemmung, Sturm über 75km/h, Hagel, Lawine, Erdrutsch usw.",
                        column1: {
                            status: true,
                            additionalInfo: "(obligatorisch)"
                        },
                        column2: {
                            additionalInfo: ""
                        },
                        column3: {
                            status: true,
                            additionalInfo: ""
                        }
                    }

            },
        ]
    },
    {
        group: [
            {
                subgroup:
                    {
                        title: "Umgebungsschäden",
                        text: "Stützmauern, Zäune, Treppen, Planien, Beleuchtungsanlagen, Rasenflächen, Ziersträucher, Bäume usw",
                        column1: {
                            status: true,
                            additionalInfo: ""
                        },
                        column2: {
                            additionalInfo: " oder "
                        },
                        column3: {
                            status: true,
                            additionalInfo: ""
                        }
                    }

            }
        ]
    },
    {
        title: "",
        group: [
            {
                subgroup:
                    {
                        title: "Diebstahl",
                        text: "Gebäudebeschädigung und Schlossänderungskosten durch Einbruch und Beraubung und/oder",
                        column1: {
                            status: true,
                            additionalInfo: ""
                        },
                        column2: {
                            additionalInfo: ""
                        },
                        column3: {
                            status: false,
                            additionalInfo: ""
                        }
                    }

            },
            {
                subgroup:
                    {
                        title: "",
                        text: "Entwendung des Hausrates durch Einbruch, Beraubung, Einfacher Diebstahl wie Taschen- oder Trickdiebstahl",
                        column1: {
                            status: false,
                            additionalInfo: ""
                        },
                        column2: {
                            additionalInfo: ""
                        },
                        column3: {
                            status: true,
                            additionalInfo: ""
                        }
                    }

            }
        ]
    },
    {
        title: "",
        group: [
            {
                subgroup:
                    {
                        title: "Wasser",
                        text: "aus Leitungen, eindringendes Regen-, Schnee- und Grundwasser usw.",
                        column1: {
                            status: true,
                            additionalInfo: ""
                        },
                        column2: {
                            additionalInfo: ""
                        },
                        column3: {
                            status: true,
                            additionalInfo: ""
                        }
                    }

            }
        ]
    },
    {
        title: "",
        group: [
            {
                subgroup:
                    {
                        title: "Glas",
                        text: "Bruch von Fenstern, Lavabos, Glaskeramik, Mobiliarglas usw.",
                        column1: {
                            status: true,
                            additionalInfo: ""
                        },
                        column2: {
                            additionalInfo: "oder"
                        },
                        column3: {
                            status: true,
                            additionalInfo: ""
                        }
                    }

            }
        ]
    },
    {
        title: "",
        group: [
            {
                subgroup:
                    {
                        title: "Erdbeben",
                        text: "Erschütterungen am Gebäude und/oder am Hausrat",
                        column1: {
                            status: true,
                            additionalInfo: ""
                        },
                        column2: {
                            status: false,
                            additionalInfo: ""
                        },
                        column3: {
                            status: true,
                            additionalInfo: ""
                        }
                    }

            }
        ]
    },
    {
        title: "",
        group: [
            {
                subgroup:
                    {
                        title: "Bauvorhaben",
                        text: "Sanierungs-, Unterhalts- und Umbauarbeiten",
                        column1: {
                            status: true,
                            additionalInfo: ""
                        },
                        column2: {
                            status: false,
                            additionalInfo: ""
                        },
                        column3: {
                            status: false,
                            additionalInfo: ""
                        }
                    }

            }
        ]
    },
    {
        title: "",
        group: [
            {
                subgroup:
                    {
                        title: "Ergänzung zur kantonalen Gebäudeversicherung",
                        text: "Nicht oder nicht genügend versicherte Kosten durch die obligatorische kantonale Gebäudeversicherung",
                        column1: {
                            status: true,
                            additionalInfo: ""
                        },
                        column2: {
                            status: false,
                            additionalInfo: ""
                        },
                        column3: {
                            status: false,
                            additionalInfo: ""
                        }
                    }

            }
        ]
    },
    {
        title: "",
        group: [
            {
                subgroup:
                    {
                        title: "Ausfall der Gebäudetechnik",
                        text: "Wärmepumpe, Boiler, Lift, Whirlpool, Sauna, elektrische Sonnenstoren, Fotovoltaik Anlagen usw.",
                        column1: {
                            status: true,
                            additionalInfo: ""
                        },
                        column2: {
                            status: false,
                            additionalInfo: ""
                        },
                        column3: {
                            status: false,
                            additionalInfo: ""
                        }
                    }

            }
        ]
    },
    {
        title: "",
        group: [
            {
                subgroup:
                    {
                        title: "Extended Coverage",
                        text: "Innere Unruhen, Böswillige Beschädigung, Fahrzeuganprall, Gebäudeeinsturz usw.",
                        column1: {
                            status: true,
                            additionalInfo: ""
                        },
                        column2: {
                            status: false,
                            additionalInfo: ""
                        },
                        column3: {
                            status: true,
                            additionalInfo: ""
                        }
                    }

            }
        ]
    },
    {
        title: "",
        group: [
            {
                subgroup:
                    {
                        title: "Ungeziefer",
                        text: "Marder, Mäuse, Ratten, Bienen und Wespen",
                        column1: {
                            status: true,
                            additionalInfo: ""
                        },
                        column2: {
                            status: false,
                            additionalInfo: ""
                        },
                        column3: {
                            status: true,
                            additionalInfo: ""
                        }
                    }

            }
        ]
    },
    {
        title: "",
        group: [
            {
                subgroup:
                    {
                        title: "Nicht genannte Gefahren",
                        text: "Sämtliche Schäden, die unter keinem Versicherungsereignis versichert, versicherbar oder nicht explizit ausgeschlossen sind",
                        column1: {
                            status: true,
                            additionalInfo: ""
                        },
                        column2: {
                            status: false,
                            additionalInfo: ""
                        },
                        column3: {
                            status: false,
                            additionalInfo: ""
                        }
                    }

            }
        ]
    },
    {
        title: "Beschädigung und Verlust",
        group: [
            {
                subgroup:
                    {
                        title: "Haushaltgeräte & Unterhaltungselektronik",
                        text: "Mobiltelefone, Tablets, Fernseher, Spielkonsolen, Foto- und Videokameras, Drohnen usw.",
                        column1: {
                            status: false,
                            additionalInfo: ""
                        },
                        column2: {
                            status: false,
                            additionalInfo: ""
                        },
                        column3: {
                            status: true,
                            additionalInfo: ""
                        }
                    },

            }, {
                subgroup:
                    {
                        title: "Medizinische Hilfsmittel",
                        text: "Hörgeräte, Rollstühle, Rollatoren, korrigierte Brillen, Insulin Pens usw.",
                        column1: {
                            status: false,
                            additionalInfo: ""
                        },
                        column2: {
                            status: false,
                            additionalInfo: ""
                        },
                        column3: {
                            status: true,
                            additionalInfo: ""
                        }
                    },

            }, {
                subgroup:
                    {
                        title: "Reisegepäck",
                        text: "Während Flugreisen oder auf Reise mit mindestens einer Übernachtung",
                        column1: {
                            status: false,
                            additionalInfo: ""
                        },
                        column2: {
                            status: false,
                            additionalInfo: ""
                        },
                        column3: {
                            status: true,
                            additionalInfo: ""
                        }
                    },

            }, {
                subgroup:
                    {
                        title: "Sportgeräte",
                        text: "Bikes, Ski, Golfausrüstungen, Fitnessgeräte, Segway usw.",
                        column1: {
                            status: false,
                            additionalInfo: ""
                        },
                        column2: {
                            status: false,
                            additionalInfo: ""
                        },
                        column3: {
                            status: true,
                            additionalInfo: ""
                        }
                    },

            }, {
                subgroup:
                    {
                        title: "Wertsachen",
                        text: "Schmucksachen, Bilder, Kunstgegenstände, Musikinstrumente usw.",
                        column1: {
                            status: false,
                            additionalInfo: ""
                        },
                        column2: {
                            status: false,
                            additionalInfo: ""
                        },
                        column3: {
                            status: true,
                            additionalInfo: ""
                        }
                    },

            }
        ]
    },
    {
        title: "",
        group: [
            {
                subgroup:
                    {
                        title: "Verderb von Tiefkühlprodukten",
                        text: "Durch den Ausfall eines Kühlaggregats",
                        column1: {
                            status: false,
                            additionalInfo: ""
                        },
                        column2: {
                            status: false,
                            additionalInfo: ""
                        },
                        column3: {
                            status: true,
                            additionalInfo: ""
                        }
                    }

            }
        ]
    },
    {
        title: "Privathaftpflicht",
        group: [
            {
                subgroup:
                    {
                        title: "Personen-, Obhuts- und Eigentümerschäden sowie Tier- und Hundehalterhaftpflicht",
                        text: "Passantin rutscht auf Ihrem Grundstück aus; die geliehene Kamera fällt runter usw.",
                        column1: {
                            status: false,
                            additionalInfo: ""
                        },
                        column2: {
                            status: false,
                            additionalInfo: ""
                        },
                        column3: {
                            status: true,
                            additionalInfo: ""
                        }
                    }

            },
            {
                subgroup:
                    {
                        title: "Benützung fremder Fahrzeuge",
                        text: "Das geliehene Auto eines Bekannten wird während der Nutzung beschädigt usw.",
                        column1: {
                            status: false,
                            additionalInfo: ""
                        },
                        column2: {
                            status: false,
                            additionalInfo: ""
                        },
                        column3: {
                            status: true,
                            additionalInfo: ""
                        }
                    }

            }
        ]
    },
    {
        title: "Gebäudehaftpflicht",
        group: [
            {
                subgroup:
                    {
                        title: "Personen- und Sachschäden",
                        text: "Passantin rutscht auf Ihrem Grundstück aus; der alte Kirschbaum fällt auf das Nachbarhaus usw.",
                        column1: {
                            status: true,
                            additionalInfo: ""
                        },
                        column2: {
                            status: false,
                            additionalInfo: ""
                        },
                        column3: {
                            status: false,
                            additionalInfo: ""
                        }
                    }

            },
            {
                subgroup:
                    {
                        title: "Bauherrenhaftpflicht",
                        text: "Kran stürzt auf das Auto einer Besucherin; Explosion auf der Baustelle sorgt für Atembeschwerden bei den Anwohnern usw.\n\nNur nötig, wenn sie das Einfamilienhaus nicht selbst bewohnen.",
                        column1: {
                            status: true,
                            additionalInfo: ""
                        },
                        column2: {
                            status: false,
                            additionalInfo: ""
                        },
                        column3: {
                            status: false,
                            additionalInfo: ""
                        }
                    }

            }
        ]
    },
    {
        title: "Rechtsschutz",
        group: [
            {
                subgroup:
                    {
                        title: "Privatrechtsschutz",
                        text: "Probleme am Arbeitsplatz, Ärger nach dem Online-Shopping; Streitigkeiten mit dem TV-Abo-Anbieter usw",
                        column1: {
                            status: false,
                            additionalInfo: ""
                        },
                        column2: {
                            status: false,
                            additionalInfo: ""
                        },
                        column3: {
                            status: true,
                            additionalInfo: ""
                        }
                    }

            },
            {
                subgroup:
                    {
                        title: "Verkehrsrechtsschutz",
                        text: "Streitigkeiten nach einem Verkehrsunfall; Ärger mit der Versicherung aufgrund eines Schadenfalls usw.",
                        column1: {
                            status: false,
                            additionalInfo: ""
                        },
                        column2: {
                            status: false,
                            additionalInfo: ""
                        },
                        column3: {
                            status: true,
                            additionalInfo: ""
                        }
                    }

            },
            {
                subgroup:
                    {
                        title: "Immobilienrechtsschutz",
                        text: "Ein Nachbar stört sich plötzlich an Ihrem alten Kirschbaum usw.",
                        column1: {
                            status: false,
                            additionalInfo: ""
                        },
                        column2: {
                            status: false,
                            additionalInfo: ""
                        },
                        column3: {
                            status: true,
                            additionalInfo: ""
                        }
                    }

            }
        ]
    }
]