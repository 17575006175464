import React, { useEffect, useState } from 'react'
import directus from '../../lib/directus';
import { Button, Card, Col, Collapse, Row } from 'react-bootstrap';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import ModulBody from './ModulBody';
import { atom, useAtom } from 'jotai';
import { atomRiskColor } from '../checks';

async function getDeckungen(id) {
    const { data } = await directus.items('deckungen').readByQuery({
        fields: ['idDeckungen', 'deckungName', 'modulNr', 'modulName', 'reihenfolgeGui', 'abhaengigkeit', 'versteckt', 'fkBranchen', 'unterpositionsKategorie', "istInAuswertung"],
        filter: {
            modulNr: id,
        },
    });
    return data;
}

export const atomNumOfSelectedFeststellungen = atom([]);
export const atomNumOfSelectedDeckungen = atom([]);



//Modul of "ModulHeader" / dropdown header
export default function ModulHeader({ modul, updateData }) {
    //State of the Dropdown (is body hidden or shown)
    const [isShown, setIsShown] = useState(false);
    //Object of all "deckungen" imported from "mydb/deckungen/"
    const [deckungen, setDeckungen] = useState([]);
    const [riskName, setRiskName] = useState('riskSquareNeutral');
    const [numOfDeckungen, setNumOfDeckungen] = useState(0);
    const [numOfFeststellungen, setNumOfFeststellungen] = useState(0);
    const [listOfRisks, setListOfRisks] = useState([]);
    const[highestRisk, setHighestRisk] = useState(4);
    const [, setRiskColorAtom] = useAtom(atomRiskColor); 
    
    //function to show or hide the dropdown body
    const openDropdown = () => {
        /* saveToLocalStorage(modul.modulNr, !isShown); */
        setIsShown(!isShown);
    };

    //Importing data from mydb/deckungen where data is from same "modul" as prop "modul"
    useEffect(() => {
        if (modul.modulNr) {
            async function fetchData() {
                const result = await getDeckungen(modul.modulNr);
                setDeckungen(result);
            }
            fetchData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modul.modulNr]);

    useEffect(() => {
        if (highestRisk === 0) {
            setRiskName("riskSquareLow");
        } else if (highestRisk === 1) {
            setRiskName("riskSquareMid");
        } else if (highestRisk === 2) {
            setRiskName("riskSquareHigh");
        } else if (highestRisk === 3) {
            setRiskName("riskSquareNothing");
        } else {
            setRiskName("riskSquareNeutral");
        }
        setRiskColorAtom(prev  => {
                const newData = { modul: modul?.modulNr, branche:  deckungen[0]?.fkBranchen, risiko: highestRisk };
                // Remove entries with modulNr === 1000
                const filteredData = prev.filter(item => item.modul !== 1000 );
                const index = filteredData.findIndex(item => item.modul === newData.modul);
                if (index !== -1) {
                    const updatedData = [...filteredData];
                    updatedData[index] = newData;
                    return updatedData; 
                }
                return [...filteredData, newData];
          });
    }, [highestRisk, listOfRisks, deckungen]);
    
    useEffect(() => {
       const filteredListOfRisks = listOfRisks.filter(item => item.istInAuswertung === 1) 
        let tmpRisk = -1;
         //if all risks are 3 (nothing) set highest Risk to 3 (nothing)
         if(filteredListOfRisks.length === listOfRisks.filter(item => item.status === 3).length) {
            setHighestRisk(4);
        }
        //otherwise get highest risk from 0 to 2
        else {
            filteredListOfRisks.forEach((riskItem) => {
            if (riskItem.status > tmpRisk && ![3, 4].includes(riskItem.status)) {
                tmpRisk = riskItem.status;
            }
        });
        
        if (tmpRisk !== -1) setHighestRisk(tmpRisk);
    }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [listOfRisks]);
    //Create a Dropdown(Card) Header for every modul in "mydb/module" where "branche" = "Elementar"
    return (
        <>
            <Card.Header>
                <Row onClick={openDropdown} style={{ cursor: "pointer" }} >
                    <Col md={3}><Card.Title>{modul.modulName}</Card.Title></Col>
                    <Col md={5}><Card.Title>Bemerkung</Card.Title></Col>
                    <Col className={riskName}>{numOfFeststellungen[modul.modulNr] ? numOfFeststellungen[modul.modulNr] : 0}/{numOfDeckungen}</Col>
                    <Col md={2}><Card.Title>Feststellung</Card.Title></Col>
                    <Col md={1}>
                        <Button variant='secondary' onClick={openDropdown}
                            aria-controls="example-collapse-text"
                            aria-expanded={isShown}>
                            {isShown ? <IoIosArrowUp /> : <IoIosArrowDown />}
                        </Button>
                    </Col>
                </Row>
            </Card.Header>
            <Collapse in={isShown}>
                <Card.Body>
                    {/* Create a Component "ModulBody" with props "deckungen" and "setListOfRisks" */}
                    <ModulBody
                        /* countFeststellungen={countFeststellungen} */
                        deckungen={deckungen}
                        /* setListOfRisks={setListOfRisks}
                        listOfRisks={listOfRisks} */
                        updateData={updateData}
                        setNumOfDeckungen={setNumOfDeckungen}
                        setNumOfFeststellungen={setNumOfFeststellungen}
                        numOfDeckungen={numOfDeckungen}
                        numOfFeststellungen={numOfFeststellungen}
                        setListOfRisks={setListOfRisks}
                    />
                </Card.Body>
            </Collapse>
        </>
    )
}
