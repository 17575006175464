import {Link, Text, View} from '@react-pdf/renderer';
import {styles} from "../styles/styles";
import {usePageReferences} from "./tocReference";

const stylesTOC = {
    column: {
        flexDirection: 'column',
    },
    row: {
        flexDirection: 'row',
        flexWrap: 'nowrap',
        alignItems: 'flex-start',
        marginTop: 3,
        textDecoration: "none",
    },
    line: {
        flexGrow: 1,
        borderBottomStyle: 'dotted',
        borderBottomWidth: 1,
        borderBottomColor: '#555',
        marginHorizontal: 2,
        height: "90%",
    },
    link: {
        fontSize: 10,
        color: "#000000",
    }
};

export function Toc({dataChecks}) {
    const items = [{
        id: `item8`,
        title: 'Einleitung',
        linkId: "bookmark_intro"
    }, {
        id: `item2`,
        title: 'Zusammenfassung',
        linkId: "bookmark_summary"
    }, {
        id: `item3`,
        title: 'Gebäudecheck EFH Feuer',
        linkId: "bookmark_fire",
        statusField: "feuerRisiko"
    }, {
        id: `item4`,
        title: 'Gebäudecheck EFH Elementar',
        linkId: "bookmark_elementar",
        statusField: "elementarRisiko"
    }, {
        id: `item5`,
        title: 'Gebäudecheck EFH Wasser',
        linkId: "bookmark_water",
        statusField: "wasserRisiko"
    }, {
        id: `item6`,
        title: 'Gebäudecheck EFH Einbruch',
        linkId: "bookmark_burglary",
        statusField: "einbruchRisiko"
    }, {
        id: `item7`,
        title: 'Versicherungsübersicht',
        linkId: "bookmark_insurance_overview"
    }];
    const references = usePageReferences();
    return (
        <View style={styles.toc}>
            <Text style={[styles.bold, {fontSize: 10}]}>Inhaltsverzeichnis</Text>
            <View style={stylesTOC.column}>
                {items.map((item, index) => {
                    if (item.statusField && (dataChecks[item.statusField] >= 3)) return null;
                    const {id, title, linkId} = item;
                    return (
                        <View key={index} style={stylesTOC.row}>
                            <Link src={`#${linkId}`} style={[stylesTOC.link, {textDecoration: 'none'}]}>{title}</Link>
                            <View style={stylesTOC.line}/>
                            <Link src={`#${linkId}`} style={[stylesTOC.link, {textDecoration: 'none'}]} render={() => {
                                const page = references.get(id);
                                return page == null ? '2' : String(page);
                            }}>
                            </Link>
                        </View>
                    );
                })}
            </View>
        </View>
    );
}