import './App.css';
import { Container } from 'react-bootstrap';
import { LoginForm } from './components/allgemein/loginForm';
import { Uebersicht } from './components/uebersicht';
import { Navigation } from './components/Navigation';
import { Allgemein } from './components/allgemein';
import { Einbruch } from './components/einbruch';
import { Feuer } from './components/feuer';
import { Wasser } from './components/wasser';
import { Print } from './components/print/index';
import { RequireAuth } from './components/authentication/requireAuth';
import { Elementar } from './components/elementar';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import directus from './lib/directus';
import { toast, ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import { atom, useAtom } from 'jotai'
import packageJson from '../package.json';
import { atomItemsToRemove } from './components/branchen/Deckungen';

export const atomChangesDone = atom(false);
export const atomUsername = atom(null);
export const atomAuthenticated = atom(localStorage.getItem('auth_token') != null);

function App({ location }) {
    const [allData, setAllData] = useState([]);
    const [authenticated, setAuthenticated] = useAtom(atomAuthenticated);
    const [username, setUsername] = useAtom(atomUsername);
    const [, setChangesDone] = useAtom(atomChangesDone);
    const [itemsToRemove, setItemsToRemove] = useAtom(atomItemsToRemove);
    const directusAuswertungen = directus.items('auswertungen');

    useEffect(() => {
        setAuthenticated(localStorage.getItem('auth_token') != null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authenticated]);

    useEffect(() => {
        if (directus.auth.token === null) {
            window.location.href = "/login";
        }
    }, []);

    useEffect(() => {
        if (authenticated) {
            directus.users.me.read().then((data) => {
                setUsername(data.first_name + " " + data.last_name);
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authenticated])

    const updateData = (newData) => {
        const index = allData.findIndex(item => item.fkDeckung === newData.fkDeckung);/* 
        console.log(newData); */
        if (index !== -1) {
            // If an object with the same idDeckungen already exists, update it
            setAllData(prevData => [
                ...prevData.slice(0, index),
                newData,
                ...prevData.slice(index + 1)
            ]);
        } else {
            // Object does not exist, add it to the array
            setAllData(prevData => [...prevData, newData]);
        }

    };

    const saveAuswertungen = (auswertungen) => {
        return new Promise(async (resolve, reject) => {

            auswertungen.forEach(async (auswertung) => {
                const fkDeckungen = auswertung.fkDeckung;
                const fkChecks = auswertung.fkCheck;
                // Check if an item with the same fkDeckung and fkCheck exists
                const existingItem = await directus.items('auswertungen').readByQuery({
                    filter: {
                        fkDeckung: { _eq: fkDeckungen },
                        fkCheck: { _eq: fkChecks },
                    },
                });

                if (existingItem.data.length > 0) {
                    // Update the existing item
                    if (auswertung.fkFeststellung) {
                        await directusAuswertungen.readOne(existingItem.data[0].idAuswertungen).then((data) => {
                            if (data.idAuswertungen) {
                                directusAuswertungen.updateOne(existingItem.data[0].idAuswertungen, {
                                    bemerkungen: auswertung.kommentar,
                                    fkFeststellung: auswertung.fkFeststellung,
                                }).then((data) => {
                                    resolve('success')
                                }).catch((error) => {
                                    reject(error)
                                })
                            } else {
                                reject('Auswertung not found')
                            }
                        }).catch((error) => {
                            reject(error)
                        })
                    } else {
                        await directusAuswertungen.updateOne(existingItem.data[0].idAuswertungen, {
                            bemerkungen: auswertung.kommentar,
                            fkFeststellung: null,
                        }).then((data) => {
                            resolve('success')
                        }).catch((error) => {
                            reject(error)
                        })
                    }
                } else {
                    // Add a new item
                    if (auswertung.fkFeststellung) {
                        await directusAuswertungen.createOne({
                            bemerkungen: auswertung.kommentar,
                            fkCheck: auswertung.fkCheck,
                            fkDeckung: auswertung.fkDeckung,
                            fkFeststellung: auswertung?.fkFeststellung ?? null,
                        }).catch((error) => {
                            reject(error)
                        });
                        resolve('success')
                    } else {
                        toast.warning('Auswertungen ohne Feststellung kann nicht gespeichert werden')
                    }
                }
            });
        }).then((data) => {
            /* async function deleteHiddenDeckungen() {
                const triggers = auswertungen.filter((auswertung) => {
                    return (['nicht vorhanden', 'in Ordnung', 'keine Beurteilung'].includes(auswertung.feststellungName) && [1, 3].includes(auswertung.fkBranche) && auswertung.versteckt === 0);
                });
                const itemsToDelete = await triggers.flatMap((trigger) => {
                    return allData.filter((item) => {
                        if (['nicht vorhanden', 'keine Beurteilung'].includes(trigger.feststellungName)) {
                            return trigger.abhaengigkeit === item.abhaengigkeit && item.versteckt === 1;
                        } else {
                            return trigger.abhaengigkeit === item.abhaengigkeit && item.versteckt === 1 && (['Verschmutzung', 'Mechanische Mängel', 'Vermoosung'].includes(item.deckungName));
                        }
                    });
                });
                if (itemsToDelete) {
                    await itemsToDelete.forEach(async (item) => {
                        const existingItems = await directusAuswertungen.readByQuery({
                            "fields": "*,fkFeststellung.*",
                            filter: {
                                fkCheck: { _eq: item.fkCheck },
                                fkDeckung: { _eq: item.fkDeckung },
                            },
                        });

                        if (existingItems.data && existingItems.data.length > 0) {
                            try {
                                await Promise.all(existingItems.data.map(async (item) => {
                                    directusAuswertungen.deleteOne(item.idAuswertungen)
                                    localStorage.removeItem("feststellung" + item.fkDeckung);
                                    localStorage.removeItem("textfeststellung" + item.fkDeckung);
                                    localStorage.removeItem("riskfeststellung" + item.fkDeckung);
                                }));
                            } catch (error) {
                            }
                        }
                    });
                }
            } */

            async function deleteHiddenDeckungen() {
                if (itemsToRemove.length > 0) {
                    // for each element in itemsToRemove, delete the corresponding auswertung from directus via its fkDeckung (if auswertung.fkDeckung === itemsToRemove[i])
                    // also setAllData = allData with the objects where auswertung.fkDeckung === itemsToRemove[i] removed
                    // also setItemsToRemove = {[]}
                    // also setChangesDone = false
                    // also there is no localStorage anymore
                    await Promise.all(itemsToRemove.map(async (item) => {
                        const existingItems = await directusAuswertungen.readByQuery({
                            "fields": "*,fkFeststellung.*",
                            filter: {
                                fkDeckung: { _eq: item },
                            },
                        });

                        if (existingItems.data && existingItems.data.length > 0) {
                            try {
                                await Promise.all(existingItems.data.map(async (item) => {
                                    directusAuswertungen.deleteOne(item.idAuswertungen)
                                }));
                            } catch (error) {
                            }
                        }
                    }));


                }
                setAllData(allData.filter((item) => {
                    return !itemsToRemove.some((itemToRemove) => {
                        return item.fkDeckung === itemToRemove;
                    }
                    );
                }));
                setItemsToRemove([]);
                setChangesDone(false);
            }

            setTimeout(() => {
                deleteHiddenDeckungen();
            }, 2000);
        });
    }


    const handleClick = () => {

        // save every item from allData where allData[i].fkDeckung does not equal to any item in itemsToRemove into a new constant
        const itemsToSave = allData.filter((item) => {
            return !itemsToRemove.some((itemToRemove) => {
                return item.fkDeckung === itemToRemove;
            }
            );
        });
        saveAuswertungen(itemsToSave).then(() => {
            toast.success("Daten wurden erfolgreich gespeichert.");
            setChangesDone(false);
        })
            .catch((error) => {
                toast.error("Daten konnten nicht gespeichert werden.");
            }).finally(() => {
            });
    }

    return (
        <>
            <ToastContainer />
            <BrowserRouter>
                <div className='header'>
                    <Navigation />
                    <div className='mb-3'></div>
                </div>
                <Container fluid>
                    <Routes>
                        <Route exact path='/login' element={<LoginForm />} />
                        <Route path='/uebersicht' element={<RequireAuth><Uebersicht /></RequireAuth>} />
                        <Route path='/allgemein' element={<RequireAuth><Allgemein /></RequireAuth>} />
                        <Route path='/einbruch'
                            element={<RequireAuth><Einbruch updateData={updateData}
                                handleClick={handleClick} /></RequireAuth>} />
                        <Route path='/elementar'
                            element={<RequireAuth><Elementar updateData={updateData}
                                handleClick={handleClick} /></RequireAuth>} />
                        <Route path='/feuer' element={<RequireAuth><Feuer updateData={updateData}
                            handleClick={handleClick} /></RequireAuth>} />
                        <Route path='/wasser' element={<RequireAuth><Wasser updateData={updateData}
                            handleClick={handleClick} /></RequireAuth>} />
                        <Route path='/print' element={<RequireAuth><Print /></RequireAuth>} />
                        <Route path='/' element={<RequireAuth><Uebersicht /></RequireAuth>} />
                    </Routes>
                </Container>
                <div className='footer' style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-end",
                    gap: "0",
                    justifyContent: "flex-end",
                    padding: 20
                }}>
                    {packageJson.version && <p style={{ margin: 0 }}>build [{packageJson.version}]</p>}
                    {username && <p style={{ margin: 0 }}>Angemeldet als: {username}</p>}
                </div>
            </BrowserRouter>
        </>
    );
}

export default App;