import React, {useEffect, useState} from 'react'
import directus from '../../lib/directus';
import {Template} from "./template";
import {PDFDownloadLink, PDFViewer} from "@react-pdf/renderer";
import {LoadingAnimation} from "../utils/loadingAnimation";
import {atomCheckID} from "../uebersicht";
import {useAtom} from "jotai";
import {useNavigate} from "react-router-dom";

export function Print() {
    const [dataChecks, setDataChecks] = useState(null);
    const [fileName, setFileName] = useState("");
    const [checkID] = useAtom(atomCheckID);
    const navigate = useNavigate();

    useEffect(() => {
        if(!checkID) {
            navigate("/uebersicht");
            return;
        }
        async function getData() {
            const dataChecks = await directus.items('checks').readByQuery({
                fields: '*.*',
                filter: {
                    idChecks: checkID
                },
            });
            setDataChecks(dataChecks.data[0])
            setFileName(`Gebaeudecheck_${dataChecks.data[0].strasse.replace(" ", "_")}_${dataChecks.data[0].ort.replace(" ", "-")}.pdf`)
        }

        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkID])

    if (!dataChecks) {
        return <LoadingAnimation/>
    }

    //Call the next components and pass the object with all the "Elementar" data
    return (
        <div className='overviewContent'>
            <div className={"print"}>
                <div style={{display: "flex", justifyContent: "center", marginBottom: "20px"}} className={"flex"}>
                    <PDFDownloadLink
                        document={<Template fileName={fileName} idChecks={checkID} dataChecks={dataChecks}/>}
                        fileName={fileName}>
                        {({blob, url, loading, error}) => (loading ? <LoadingAnimation/> : 'Download PDF!')}
                    </PDFDownloadLink>
                </div>
                <PDFViewer style={{width: "100%", height: "100vh"}}>
                    <Template fileName={fileName} idChecks={checkID} dataChecks={dataChecks}/>
                </PDFViewer>
            </div>
        </div>
    );
}
