import {styles} from "./styles/styles";
import {Text, View} from "@react-pdf/renderer";
import React, {useEffect, useState} from "react";
import directus from "../../lib/directus";
import {statusIcon} from "./summary";
import {Arrow} from "./arrow";

export function GroupSpecial({
                                 module,
                                 branchID,
                                 moduleID,
                                 checkID,
                                 includeIds,
                                 showModuleNameInsteadOfDeckung = false,
                                 overwrittenTitle
                             }) {
    const [data, setData] = useState([]);

    const includeStatus = [0, 1, 2]

    useEffect(() => {
        directus.items("auswertungen").readByQuery({
            fields: "*.*",
            filter: {
                fkCheck: checkID,
                "_and": [
                    {"fkDeckung": {"_nnull": true}},
                    {"fkDeckung": {"fkBranchen": {"_eq": branchID}}},
                    {"fkDeckung": {"modulNr": {"_in": moduleID}}},
                    {"fkDeckung": {"idDeckungen": {"_in": includeIds}}},
                    {"fkFeststellung": {"status": {"_in": includeStatus}}},
                ],
                fkFeststellung: {_nnull: true},
            }
        }).then(response => {
            const sorted = response.data.sort((a, b) => {
                if (a.fkDeckung.modulNr === b.fkDeckung.modulNr) {
                    if (!a.fkDeckung.abhaengigkeit) {
                        return -1
                    } else if (!b.fkDeckung.abhaengigkeit) {
                        return 1
                    }
                    return a.fkDeckung.abhaengigkeit - b.fkDeckung.abhaengigkeit
                }
                return b.fkDeckung.modulNr - a.fkDeckung.modulNr
            })
            setData([sorted]);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!data || data.length === 0) return null;
    return data?.map((group, groupIndex) => {
        if (group.some(item => item.fkFeststellung?.textBericht)) {
            return group.sort((a, b) => a.fkDeckung.reihenfolgePdf < b.fkDeckung.reihenfolgePdf)?.map((auswertung, index) => {
                return <View wrap={false} key={`feststellung_wrapper_${moduleID}_${index}`}>
                    <View key={`feststellung_${module}_${index}`}>
                        {(index === 0) && <Text
                            style={styles.groupTitle}>{overwrittenTitle ?? auswertung.fkDeckung[showModuleNameInsteadOfDeckung ? "modulName" : "deckungName"]}</Text>}
                        {auswertung?.fkFeststellung?.textBericht && auswertung.fkFeststellung.status < 3 &&
                            <View style={styles.flex_children}>
                                <View style={styles.flex_component1}>
                                    <div style={{width: "1.1cm", textAlign: "center"}}>
                                        <div
                                            style={[styles[statusIcon[auswertung.fkFeststellung.status]], {marginLeft: 5}]}></div>
                                    </div>
                                    <Text
                                        style={styles.textFeststellung}>{auswertung.fkFeststellung.textBericht ?? `fehlende Information im Feld "fkFeststellung.textBericht"`}</Text>
                                </View>
                                <View style={styles.flex_component2}>
                                    {auswertung.fkFeststellung.vorschlagBericht &&
                                        <>
                                            <Arrow></Arrow>
                                            <Text style={styles.textFeststellung}
                                                  render={() => auswertung.fkFeststellung.vorschlagBericht}></Text>
                                        </>
                                    }
                                </View>
                            </View>}
                    </View>
                </View>
            })
        }
        return null;
    })
}