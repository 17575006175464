import React from "react";
import {StyleSheet, Text, View} from "@react-pdf/renderer";

const styles = StyleSheet.create({
    row: {
        flexDirection: "column",
        alignItems: "flex-start",
        padding: 4,
        borderBottom: "1px solid grey",
        borderTop: "1px solid grey",
        marginBottom: 4,
    },
    group_row: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 4,
    },
    description: {
        width: {
            width: "70%"
        },
        title: {
            fontSize: 11,
            fontWeight: "bold",
        },
        subtitle: {
            fontSize: 10,
            fontWeight: "bold",
        },
        text: {
            fontSize: 10,
            fontWeight: "normal"
        }
    },
    extra_small: {
        fontSize: 8,
    },
    column: {
        width: "15%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
    },
    column_small: {
        width: "10%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-start",
    },
});

const TableHeader = (props) => {
    return <View {...props} style={styles.row}>
        <View key={"table_header"}>
            <View style={styles.group_row}>
                <View style={styles.description.width}>
                    <Text style={styles.description.title}>Lauernde Gefahren und versicherbare Risiken</Text>
                </View>
                <View style={styles.column}>
                    <Text style={styles.description.subtitle}>Gebäude</Text>
                </View>
                <View style={styles.column}>
                    <Text style={styles.description.subtitle}>Haushalt</Text>
                </View>
            </View>
        </View>
    </View>
};

export default TableHeader;