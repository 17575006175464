import {styles} from "./styles/styles";
import {Text, View} from "@react-pdf/renderer";
import React, {useEffect, useState} from "react";
import directus from "../../lib/directus";
import {statusIcon} from "./summary";
import {Arrow} from "./arrow";

export function GroupElementar({module, branchID, moduleID, checkID, showModuleNameInsteadOfDeckung = false}) {
    const [data, setData] = useState([]);

    // const excludeFestellungen = ["zulassung gl", "hochwasser", "pflichtabstände", "abstände zu brennbarem", "reinigungsarbeiten", "rutsch 12", "lawine 13", "sturz 11"]; 10 ab da wasser
    const excludeDeckungen = [50, 7, 27, 33, 39, 45, 28, 34, 40, 46, 29, 35, 41, 47, 10]
    const includeStatus = [0, 1, 2]
    let specialTitles = {
        8: "Hochwasser",
        12: "Rutschung",
        16: "Innenbeleuchtung bei Abwesenheit",
    };

    useEffect(() => {
        directus.items("auswertungen").readByQuery({
            fields: "*.*",
            filter: {
                fkCheck: checkID,
                "_and": [
                    {"fkDeckung": {"_nnull": true}},
                    {"fkDeckung": {"fkBranchen": {"_eq": branchID}}},
                    {"fkDeckung": {"modulNr": {"_in": moduleID}}},
                    {"fkDeckung": {"idDeckungen": {"_nin": excludeDeckungen}}},
                    {"fkFeststellung": {"status": {"_in": includeStatus}}},
                ],
                fkFeststellung: {_nnull: true},
            }
        }).then(response => {
            const groupedByAbhaeningkeit = response.data.reduce((acc, curr) => {
                let index = curr.fkDeckung.abhaengigkeit ?? 1;
                if (!acc[index]) {
                    acc[index] = [];
                }
                acc[index].push(curr);
                return acc;
            }, []);
            const sortedResponse = groupedByAbhaeningkeit.map(item => {
                return item.sort((a, b) => {
                    return a.fkDeckung.reihenfolgePdf - b.fkDeckung.reihenfolgePdf;
                })
            })
            setData(sortedResponse);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!data || data.length === 0) return null;
    return data?.map((group) => {
        if(group.some(item => item.fkFeststellung?.textBericht)) {
            return group?.map((auswertung, index) => {
                return <View wrap={false} key={`feststellung_wrapper_${moduleID}_${index}`}>
                    <View key={`feststellung_${module}_${index}`}>
                        {((auswertung.fkDeckung.abhaengigkeit === null) || (auswertung.fkDeckung.abhaengigkeit != null && index === 0)) &&
                            <Text
                                style={styles.groupTitle}>{Object.keys(specialTitles).includes(auswertung.fkDeckung.idDeckungen.toString()) ? specialTitles[auswertung.fkDeckung.idDeckungen] : (auswertung.fkDeckung[showModuleNameInsteadOfDeckung ? "modulName" : "deckungName"])}</Text>}
                        {auswertung?.fkFeststellung?.textBericht && auswertung?.fkFeststellung?.status < 3 &&
                            <View style={styles.flex_children}>
                                <View style={styles.flex_component1}>
                                    <div style={{width: "1.1cm", textAlign: "center"}}>
                                        <div
                                            style={[styles[statusIcon[auswertung.fkFeststellung.status]], {marginLeft: 5}]}></div>
                                    </div>
                                    <Text style={styles.textFeststellung}>{auswertung.fkFeststellung.textBericht}</Text>
                                </View>
                                <View style={styles.flex_component2}>
                                    {auswertung.fkFeststellung.vorschlagBericht &&
                                        <>
                                            <Arrow></Arrow>
                                            <Text style={styles.textFeststellung}
                                                  render={() => auswertung.fkFeststellung.vorschlagBericht}></Text>
                                        </>
                                    }
                                </View>
                            </View>
                        }
                    </View>
                </View>
            })
        }
        return null;
    })
}