import {styles} from "./styles/styles";
import {Text, View} from "@react-pdf/renderer";
import React, {useEffect, useState} from "react";
import directus from "../../lib/directus";
import {statusIcon} from "./summary";
import {Arrow} from "./arrow";

export function Group({module, branchID, moduleID, checkID, showModuleNameInsteadOfDeckung = false}) {
    const [data, setData] = useState([]);

    // const excludeFestellungen = ["zulassung gl", "hochwasser", "pflichtabstände", "abstände zu brennbarem", "reinigungsarbeiten", "rutsch", "lawine", "sturz"];
    const excludeDeckungen = [50, 7, 27, 33, 39, 45, 28, 34, 40, 46, 29, 35, 41, 47, 12, 13, 11]
    const includeStatus = [0, 1, 2]

    useEffect(() => {
        directus.items("auswertungen").readByQuery({
            fields: "*.*",
            filter: {
                fkCheck: checkID,
                "_and": [
                    {"fkDeckung": {"_nnull": true}},
                    {"fkDeckung": {"fkBranchen": {"_eq": branchID}}},
                    {"fkDeckung": {"modulNr": {"_in": moduleID}}},
                    {"fkDeckung": {"idDeckungen": {"_nin": excludeDeckungen}}},
                    {"fkFeststellung": {"status": {"_in": includeStatus}}},
                ],
                fkFeststellung: {_nnull: true},
            }
        }).then(response => {
            if (response.data.length > 1) {
                const groupedByAbhaeningkeit = response.data.reduce((acc, curr) => {
                    let index = curr.fkDeckung.abhaengigkeit;
                    if (moduleID.includes(14)) {
                        index = 0;
                    }
                    if (!acc[index]) {
                        acc[index] = [];
                        if (moduleID.includes(14)) {
                            acc[index].push({
                                fkDeckung: {deckungName: "Präventionsartikel", idDeckungen: 15},
                                fkFeststellung: {status: 0}
                            });
                        }
                    }
                    acc[index].push(curr);
                    return acc;
                }, []);
                setData(groupedByAbhaeningkeit);
            } else {
                setData([response.data]);
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (!data || data.length === 0) return null;
    return data?.map((group, groupIndex) => {
        if (group.some(item => item.fkFeststellung?.textBericht)) {
            return group.sort((a, b) => a.fkDeckung.reihenfolgePdf < b.fkDeckung.reihenfolgePdf)?.map((auswertung, index) => {
                return <View wrap={false} key={`feststellung_wrapper_${moduleID}_${index}`}>
                    <View key={`feststellung_${module}_${index}`}>
                        {(index === 0 || group.length === 1) && <Text
                            style={styles.groupTitle}>{auswertung.fkDeckung[showModuleNameInsteadOfDeckung ? "modulName" : "deckungName"]}</Text>}
                        {auswertung?.fkFeststellung?.textBericht && ((group.length > 1 && index > 0) || (group.length === 1 && auswertung.fkFeststellung.status < 3)) &&
                            <View style={styles.flex_children}>
                                <View style={styles.flex_component1}>
                                    <div style={{width: "1.1cm", textAlign: "center"}}>
                                        <div
                                            style={[styles[statusIcon[auswertung.fkFeststellung.status]], {marginLeft: 5}]}></div>
                                    </div>
                                    <Text
                                        style={styles.textFeststellung}>{auswertung.fkFeststellung.textBericht ?? `fehlende Information im Feld "fkFeststellung.textBericht"`}</Text>
                                </View>
                                <View style={styles.flex_component2}>
                                    {auswertung.fkFeststellung.vorschlagBericht &&
                                        <>
                                            <Arrow></Arrow>
                                            <Text style={styles.textFeststellung}
                                                  render={() => auswertung.fkFeststellung.vorschlagBericht}></Text>
                                        </>
                                    }
                                </View>
                            </View>
                        }
                    </View>
                </View>
            })
        }
        return null
    })
}