import {styles} from "./styles/styles";
import {Page, Text, View} from "@react-pdf/renderer";
import TableHeader from "./table/tableHeader";
import TableRow from "./table/tableRow";
import {tableData} from "./texts/insurance_overview";
import {Footer} from "./footer";
import React from "react";
import {TocReference} from "./toc/tocReference";

export function InsuranceOverview({dataChecks}) {
    return <Page bookmark={{fit: true, title: "Versicherungsübersicht"}} key={"page_insurance_overview"} wrap size="A4"
                 style={styles.page}>
        <Text key={"page_insurance_overview_title"} id={"bookmark_insurance_overview"}
              style={styles.section.header}>Versicherungsübersicht</Text>
        <TocReference key={"page_insurance_overview_ref"} id={"item7"}/>
        <Text key={"page_insurance_overview_text_1"} style={styles.textIntro}>Die folgende Übersicht zeigt Ihnen, wo rund ums
            Einfamilienhaus Gefahren lauern
            und wie Sie diese Risiken versichern können.</Text>
        <Text key={"page_insurance_overview_text_2"} style={styles.textIntro}>Unter <Text
            style={{fontWeight: "bold"}}>Gebäude</Text> fällt Ihr Einfamilienhaus
            samt baulichen Einrichtungen und Ausstattungen. Diese Risiken können durch unsere Gebäudeversicherung
            DREIDIMENSIONAL abgedeckt werden. Für die Gefahren Feuer und Elementar besteht in den Kantonen Glarus
            und St. Gallen über die Kantonale Gebäudeversicherung ein obligatorischer Versicherungsschutz. Im Kanton
            Schwyz können diese Risiken in die Gebäudeversicherung DREIDIMENSIONAL eingeschlossen werden.</Text>
        <Text key={"page_insurance_overview_text_3"} style={styles.textIntro}>Zum <Text
            style={{fontWeight: "bold"}}>Hausrat</Text> gehören alle bewegliche
            Sachen wie Einrichtungsgegenstände, Gebrauchsgegenstände, Verbrauchsgüter, Wertsachen, Fahrnisbauten,
            Elektrofahrräder und Haustiere. Diese Sachen können über unsere Haushaltversicherung SIEBENSACHEN
            versichert werden.</Text>
        <Text key={"page_insurance_overview_text_4"} style={styles.textIntro}>Haben Sie Fragen? Wenden Sie sich ungeniert an
            Ihren Versicherungsberater.</Text>
        <TableHeader key={"page_insurance_overview_text_5"} fixed/>
        <View key={"page_insurance_overview_text_6"} wrap style={styles.table}>
            <TableRow groups={tableData}/>
        </View>
        <Footer keyValue={"page_insurance_footer"} styles={styles} dataChecks={dataChecks}/>
    </Page>
}