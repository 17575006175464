import React, { useEffect } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { useNavigate } from "react-router-dom";
import directus from "../lib/directus";
import { styles } from "./print/styles/styles";
import { LoadingAnimation } from "./utils/loadingAnimation";
import { atomCheckID } from "./uebersicht";
import { atom, useAtom } from "jotai";

export const atomTriggerStatus = atom([{}]);
export const atomRiskColor = atom([]);

async function getTriggerStatus(fkDeckung) {
    let status = [];
    const response = await directus.items('deckungen_statusTriggers').readByQuery({
        fields: ['deckungen_idDeckungen', 'statusTriggers_idStatus'],
        filter: {
            deckungen_idDeckungen: fkDeckung,
        },
    });
    const promises = response.data.map(async (item) => {
        const fetchedStatus = await directus.items('statusTriggers').readByQuery({
            fields: ['statusNr', 'statusText'],
            filter: {
                idStatus: item.statusTriggers_idStatus,
            },
        });
        return fetchedStatus.data;
    });
    const results = await Promise.all(promises);
    status = status.concat(results.flat());
    if (status.length > 0) {
        return status;
    }
}


export default function Checks() {

    const [deckungsCount, setDeckungsCount] = React.useState([]);
    const [deckungen, setDeckungen] = React.useState([]);
    const [auswertungen, setAuswertungen] = React.useState([]);
    const [feststellungen, setFeststellungen] = React.useState([]);
    const [riskStatusList, setRiskStatusList] = React.useState();
    const [imageName, setImageName] = React.useState("");
    const [idChecks] = useAtom(atomCheckID);
    const [feuerStatusState, setFeuerStatus] = React.useState({risiko: 4});
    const [wasserStatusState, setWasserStatus] = React.useState({risiko: 4});
    const [einbruchStatusState, setEinbruchStatus] = React.useState({risiko: 4});
    const [elementarStatusState, setElementarStatus] = React.useState({risiko: 4});
    const [riskWasserStatus, setRiskWasserStatus] = React.useState (4);
    const [riskFeuerStatus, setRiskFeuerStatus] = React.useState(4);
    const [riskEinbruchStatus, setRiskEinbruchStatus] = React.useState(4);
    const [riskElementarStatus, setRiskElementarStatus] = React.useState(4);
    const [triggerStatus, setTriggerStatus] = useAtom(atomTriggerStatus);
    const [riskColorAtom, setRiskColorAtom] = useAtom(atomRiskColor);

    const [loading, setLoading] = React.useState(false);
    const navigate = useNavigate();

    // eslint-disable-next-line react-hooks/exhaustive-deps
    async function handleUpload(event) {
        setLoading(true);
        if (event.target.files && event.target.files[0]) {
            let img = event.target.files[0];
            const newFormData = new FormData();
            newFormData.append("file", img)
            await directus.files.createOne(newFormData).then((response) => {
                directus.items('checks').updateOne(idChecks, {
                    object_image: response.id
                })
                setImageName(response.title);
            }).finally(() => {
                setLoading(false);
            })
        }
    }
    const handlePrint = () => {
        navigate('/print', { replace: true, state: { "idChecks": idChecks } });
    }

    async function getCountData() {
        if (Object.keys(localStorage).filter(key => key.includes("deckungCounter")) && idChecks !== null) {
            directus.items('auswertungen').readByQuery({
                filter: {
                    fkCheck: idChecks
                },
                fields: ['idAuswertungen', 'fkFeststellung.*', 'fkDeckung.*'],
            }).then((response) => {
                setAuswertungen(response.data);
            });
            directus.items('deckungen').readByQuery({
                fields: ['idDeckungen', 'fkBranchen', 'abhaengigkeit', 'versteckt', 'fkFeststellung.status', 'istInAuswertung']
            }).then((response) => {
                setDeckungen(response.data);
            });
            directus.items('feststellungen').readByQuery({
                fields: ['idFeststellungen', 'fkDeckung', 'status'],
                limit: 200,
            }).then((response) => {
                setFeststellungen(response.data);
            });
        }
    }
    // create a function to get the name of the picture in object_image in checks
    async function getPicture() {
        if (idChecks) {
          await directus.items('checks')
            .readOne(idChecks, {
              fields: ["*, object_image.title", "feuerRisiko", "wasserRisiko", "elementarRisiko", "einbruchRisiko"]
            })
            .then((response) => {
            setImageName(response.object_image.title);
              const branchen = [
                { name: "feuer", risiko: response?.feuerRisiko },
                { name: "wasser", risiko: response?.wasserRisiko },
                { name: "elementar", risiko: response?.elementarRisiko },
                { name: "einbruch", risiko: response?.einbruchRisiko }
              ];
      
              const updatedRiskColorAtom = branchen.reduce((acc, { name, risiko }) => {
                const branchNumber = {
                  feuer: 1,
                  wasser: 2,
                  elementar: 3,
                  einbruch: 4
                }[name];
      
                if (risiko !== undefined && !riskColorAtom.some(item => item.branche === branchNumber && item.risiko === risiko)) {
                  acc.push({ modul: 1000, branche: branchNumber, risiko });
                }
                return acc;
              }, []);
      
              if (riskColorAtom.length === 0) {
                setRiskColorAtom(updatedRiskColorAtom);
                }
      
              if (!riskColorAtom.some(item => item.branche === 1)) {
                setFeuerStatus(response?.feuerRisiko );
              }
              if (!riskColorAtom.some(item => item.branche === 2)) {
                setWasserStatus( response?.wasserRisiko );
              }
              if (!riskColorAtom.some(item => item.branche === 3)) {
                setElementarStatus(response?.elementarRisiko );
              }
              if (!riskColorAtom.some(item => item.branche === 4)) {
                setEinbruchStatus(response?.einbruchRisiko );
              }
            })
            .catch((error) => {
              // Handle error
            });
        } else {
          setImageName("Bild: Kein Bild hochgeladen");
        }
      }


    async function getStatus() {
        // get the highes status of feststellungen where fkDeckung = auswertung.fkDeckung, sort by deckung.fkBranchen. IMPORTANT! For every branche only safe the highest status (and keep it safed), the order of status is from lowest to highest: 3,0,1,2
        let statusElementar = 4;
        let statusFeuer = 4;
        let statusWasser = 4;
        let statusEinbruch = 4;

        const filteredDeckungen = deckungen.filter(item => item.istInAuswertung === 1);

        auswertungen?.forEach((auswertung) => {
            filteredDeckungen?.forEach((deckung) => {
                if (deckung?.idDeckungen === auswertung?.fkDeckung.idDeckungen) {
                    feststellungen?.forEach((feststellung) => {
                        if (auswertung?.fkFeststellung.idFeststellungen === feststellung?.idFeststellungen) {
                            switch (deckung.fkBranchen) {
                                case 1:
                                    if ((feststellung?.status > statusFeuer || statusFeuer === 4) && feststellung?.status < 3) {
                                        statusFeuer = feststellung?.status;
                                    }
                                    break;
                                case 2:
                                    if ((feststellung?.status > statusWasser || statusWasser === 4) && feststellung?.status < 3) {
                                        statusWasser = feststellung?.status;
                                    }
                                    break;
                                case 3:
                                    if ((feststellung?.status > statusElementar || statusElementar === 4) && feststellung?.status < 3) {
                                        statusElementar = feststellung?.status;
                                    }
                                    break;
                                case 4:
                                    if ((feststellung?.status > statusEinbruch || statusEinbruch === 4) && feststellung?.status < 3) {
                                        statusEinbruch = feststellung?.status;
                                    }
                                    break;
                                default:
                                    break;
                            }
                        }
                    })
                }
            })
        })
        setRiskStatusList({
            statusFeuer: statusFeuer,
            statusWasser: statusWasser,
            statusElementar: statusElementar,
            statusEinbruch: statusEinbruch
        });
    }
    const safeStatusToDB = () => {
        directus.items('checks').updateOne(idChecks, {
            feuerRisiko: riskStatusList.statusFeuer,
            wasserRisiko: riskStatusList.statusWasser,
            elementarRisiko: riskStatusList.statusElementar,
            einbruchRisiko: riskStatusList.statusEinbruch,
        }).then(() => {
        })

    }

    async function getDeckungsCountFromData() {
        let countElementar = 0;
        let countElementarDeckungen = 0;
        let countEinbruch = 0;
        let countEinbruchDeckungen = 0;
        let countFeuer = 0;
        let countFeuerDeckungen = 0;
        let countWasser = 0;
        let countWasserDeckungen = 0;

        await deckungen.forEach((deckung) => {
            switch (deckung.fkBranchen) {
                case 1:
                    // holzfeuerung + Ölheizung
                    if ([24, 30, 36, 42, 70].includes(deckung.idDeckungen)) {
                        if (auswertungen.filter(auswertung => auswertung.fkDeckung.idDeckungen === deckung.idDeckungen)[0]?.fkFeststellung?.status === 1) {
                            countFeuerDeckungen += 6;
                        } else if (auswertungen.filter(auswertung => auswertung.fkDeckung.idDeckungen === deckung.idDeckungen)[0]?.fkFeststellung?.status === 0) {
                            countFeuerDeckungen += 4;
                        } else {
                            countFeuerDeckungen++;
                        }
                    }
                    // Gasheizung
                    else if ([48].includes(deckung.idDeckungen)) {
                        if (auswertungen.filter(auswertung => auswertung.fkDeckung.idDeckungen === deckung.idDeckungen)[0]?.fkFeststellung?.status === 2) {
                            countFeuerDeckungen += 2;
                        } else {
                            countFeuerDeckungen++;
                        }
                    }
                    // Handfeuerlöscher
                    else if ([51].includes(deckung.idDeckungen)) {
                        if (auswertungen.filter(auswertung => auswertung.fkDeckung.idDeckungen === deckung.idDeckungen)[0]?.fkFeststellung?.feststellungName !== 'nicht vorhanden') {
                            countFeuerDeckungen += 2;
                        } else {
                            countFeuerDeckungen++;
                        }
                    }
                    // Alle Unterpositionen Holzfeuerung, Gasheizung, Ölheizung, Handfeuerlöscher
                    else if (![25, 26, 27, 28, 29, 31, 32, 33, 34, 35, 37, 38, 39, 40, 41, 43, 44, 45, 46, 47, 64, 65, 66, 67, 68, 69, 72, 52].includes(deckung.idDeckungen)) {
                        countFeuerDeckungen++;
                    }
                    break;
                case 2:
                    countWasserDeckungen++;
                    break;
                case 3:
                    if (deckung.idDeckungen === 127 && auswertungen.filter(auswertung => auswertung.fkDeckung.idDeckungen === deckung.idDeckungen)[0]?.fkFeststellung?.status === 2) {
                        countElementarDeckungen += 3;
                    } else if (![1, 2].includes(deckung.idDeckungen)) {
                        countElementarDeckungen++;
                    }
                    break;
                case 4:
                    countEinbruchDeckungen++;
                    break;
                default:
                    break;
            }
            auswertungen.forEach((auswertung) => {
                if (deckung.idDeckungen === auswertung.fkDeckung.idDeckungen) {
                    switch (deckung.fkBranchen) {
                        case 1:
                            countFeuer++;
                            break;
                        case 2:
                            countWasser++;
                            break;
                        case 3:
                            countElementar++;
                            break;
                        case 4:
                            countEinbruch++;
                            break;
                        default:
                            break;
                    }
                }
            })
        })
        setDeckungsCount([
            { numOfDeckungen: 0, numOfFeststellungen: 0 },
            {
                numOfDeckungen: JSON.parse(countElementarDeckungen),
                numOfFeststellungen: JSON.parse(countElementar),
            },
            {
                numOfDeckungen: JSON.parse(countFeuerDeckungen),
                numOfFeststellungen: JSON.parse(countFeuer),
            },
            {
                numOfDeckungen: JSON.parse(countWasserDeckungen),
                numOfFeststellungen: JSON.parse(countWasser),
            },
            { numOfDeckungen: 0, numOfFeststellungen: 0 },
            { numOfDeckungen: 0, numOfFeststellungen: 0 },
            { numOfDeckungen: 0, numOfFeststellungen: 0 },
            { numOfDeckungen: 0, numOfFeststellungen: 0 },
            { numOfDeckungen: 0, numOfFeststellungen: 0 },
            { numOfDeckungen: 0, numOfFeststellungen: 0 },
            { numOfDeckungen: 0, numOfFeststellungen: 0 },
            { numOfDeckungen: 0, numOfFeststellungen: 0 },
            { numOfDeckungen: 0, numOfFeststellungen: 0 },
            { numOfDeckungen: 0, numOfFeststellungen: 0 },
            { numOfDeckungen: 0, numOfFeststellungen: 0 },
            { numOfDeckungen: 0, numOfFeststellungen: 0 },
            {
                numOfDeckungen: JSON.parse(countEinbruchDeckungen),
                numOfFeststellungen: JSON.parse(countEinbruch)
            },
            { numOfDeckungen: 0, numOfFeststellungen: 0 },
            { numOfDeckungen: 0, numOfFeststellungen: 0 }
        ])
    }

    const getDeckungsCount = (branche) => {
        let countF = 0;
        let countD = 0;
        let result = "";
        try {
            switch (branche) {
                //Elementar
                case 1:
                    countD = deckungsCount[1].numOfDeckungen;
                    countD += deckungsCount[5].numOfDeckungen;
                    countD += deckungsCount[6].numOfDeckungen;
                    countD += deckungsCount[8].numOfDeckungen;

                    countF = deckungsCount[1].numOfFeststellungen;
                    countF += deckungsCount[5].numOfFeststellungen;
                    countF += deckungsCount[6].numOfFeststellungen;
                    countF += deckungsCount[8].numOfFeststellungen;

                    result = JSON.stringify(countF) + "/" + JSON.stringify(countD);

                    return result;
                //Einbruch
                case 2:
                    countD += deckungsCount[16].numOfDeckungen;
                    countD += deckungsCount[17].numOfDeckungen;

                    countF = deckungsCount[16].numOfFeststellungen;
                    countF += deckungsCount[17].numOfFeststellungen;

                    result = JSON.stringify(countF) + "/" + JSON.stringify(countD);

                    return result;
                //Feuer
                case 3:
                    countD = deckungsCount[2].numOfDeckungen;
                    countD += deckungsCount[7].numOfDeckungen;
                    countD += deckungsCount[9].numOfDeckungen;
                    countD += deckungsCount[10].numOfDeckungen;
                    countD += deckungsCount[11].numOfDeckungen;
                    countD += deckungsCount[12].numOfDeckungen;
                    countD += deckungsCount[14].numOfDeckungen;
                    countD += deckungsCount[15].numOfDeckungen;
                    countD += deckungsCount[18].numOfDeckungen;

                    countF = deckungsCount[2].numOfFeststellungen;
                    countF += deckungsCount[7].numOfFeststellungen;
                    countF += deckungsCount[9].numOfFeststellungen;
                    countF += deckungsCount[10].numOfFeststellungen;
                    countF += deckungsCount[11].numOfFeststellungen;
                    countF += deckungsCount[12].numOfFeststellungen;
                    countF += deckungsCount[14].numOfFeststellungen;
                    countF += deckungsCount[15].numOfFeststellungen;
                    countF += deckungsCount[18].numOfFeststellungen;

                    result = JSON.stringify(countF) + "/" + JSON.stringify(countD);

                    return result;
                //Wasser
                case 4:
                    countD = deckungsCount[3].numOfDeckungen;
                    countD += deckungsCount[4].numOfDeckungen;
                    countD += deckungsCount[13].numOfDeckungen;

                    countF = deckungsCount[3].numOfFeststellungen;
                    countF += deckungsCount[4].numOfFeststellungen;
                    countF += deckungsCount[13].numOfFeststellungen;

                    result = JSON.stringify(countF) + "/" + JSON.stringify(countD);

                    return result;

                default:
                    break;
            }
        } catch (error) {
        }
    }

    const setRisk = (risk) => {
        if (risk === 0) {
            return ("riskDotLow");
        } else if (risk === 1) {
            return ("riskDotMid");
        } else if (risk === 2) {
            return ("riskDotHigh");
        } else if (risk === 3) {
            return ("riskDotNothing");
        } else {
            return ("riskDotNeutral");
        }
    }

    useEffect(() => {
        // Initialize highest risks for each branch
        const highestRisks = {
            1: { risiko: -1 },
            2: { risiko: -1 },
            3: { risiko: -1 },
            4: { risiko: -1 }
        };

        // Group the riskColorAtom objects by branch
        const groupedRisks = riskColorAtom.reduce((acc, riskItem) => {
            const branch = riskItem.branche;
            if (!acc[branch]) {
                acc[branch] = [];
            }
            acc[branch].push(riskItem);
            return acc;
        }, {});

        // Find the highest risk within each group
        Object.values(groupedRisks).forEach((risks) => {
            const highestRisk = risks.reduce((maxRisk, riskItem) => {
                if (riskItem?.risiko > maxRisk?.risiko && ![3, 4].includes(riskItem?.risiko)) {
                    return riskItem;
                }
                return maxRisk;
            }, { risiko: -1 });

            if (highestRisk?.risiko > highestRisks[highestRisk.branche]?.risiko) {
                highestRisks[highestRisk.branche] = highestRisk;
            }
        });

        // Update state variables based on highest risks
        Object.entries(highestRisks).forEach(([branch, highestRisk]) => {
            if (highestRisk?.risiko !== -1) {
                switch (parseInt(branch)) {
                    case 1:
                        setFeuerStatus(highestRisk?.risiko);
                        break;
                    case 2:
                        setWasserStatus(highestRisk?.risiko);
                        break;
                    case 3:
                        setElementarStatus(highestRisk?.risiko);
                        break;
                    case 4:
                        setEinbruchStatus(highestRisk?.risiko);
                        break;
                    default:
                        break;
                }
            }
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        function fetchData() {
            // make the asyncronous call for deckungen wait for all the data to be fetched
            deckungen
                .sort(function (a, b) {
                    return a.reihenfolgeGui - b.reihenfolgeGui
                })
                .map(async (deckung) => {
                    if (deckung.versteckt === 1 && deckung.abhaengigkeit !== null) {
                        if (!triggerStatus?.some((item) => item.fkDeckung === deckung.idDeckungen)) {
                            const stat = await getTriggerStatus(deckung.idDeckungen);

                            stat?.map((item) => {
                                item.fkDeckung = deckung.idDeckungen;
                                item.abhaengigkeit = deckung.abhaengigkeit;
                                return null;
                            })
                            // add stat to triggerStatus array
                            stat?.map((item) => {
                                setTriggerStatus(prev => [...prev, item])
                            })
                        }
                    }
                })
            return null;
        }
        fetchData();
    }, [deckungen]);

    useEffect(() => {
        async function setClassName() {
            setRiskWasserStatus(setRisk(wasserStatusState));
            setRiskFeuerStatus(setRisk(feuerStatusState));
            setRiskEinbruchStatus(setRisk(einbruchStatusState));
            setRiskElementarStatus(setRisk(elementarStatusState));
        }
        setClassName();
    }, [wasserStatusState, feuerStatusState, einbruchStatusState, elementarStatusState])

    useEffect(() => {
        for (let index = 0; index < 20; index++) {
            const result = localStorage.getItem("deckungCounter" + index)
            setDeckungsCount(deckungsCount => [...deckungsCount, JSON.parse(result)]);
        }
        try {
            getCountData();
            getDeckungsCountFromData();
        } catch (error) {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (Object.keys(localStorage).filter(key => key.includes("deckungCounter"))) {
            getDeckungsCountFromData();
        }
        getStatus();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auswertungen, deckungen, feststellungen])

    useEffect(() => {
        try {
            if (Object.values(riskStatusList).some(item => item !== 4)) {
                safeStatusToDB();
            }
        } catch (error) {
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [riskStatusList])

    useEffect(() => {
        getPicture();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feststellungen, riskStatusList, deckungsCount])

    return (
        <Card>
            <Card.Header>
                <Row>
                    <Col md={4}><Card.Title>Zusammenfassung</Card.Title></Col>
                    <Col md={3}><Card.Title>erledigt / Total</Card.Title></Col>
                    <Col md={2}>
                        {loading && <LoadingAnimation />}
                        <Button variant={"secondary"}
                            onClick={() => document.querySelector("input#changeImage").click()}>Bild
                            hochladen</Button>
                        <input id={"changeImage"} hidden={true} style={styles.secondaryButton} type={"file"}
                            onChange={handleUpload} className={"btn btn-secondary"} name={"Bild hochladen"} />
                    </Col>
                    <Col md={1}></Col>
                    <Col md={2}><Button onClick={handlePrint}>Drucken</Button></Col>
                </Row>
                <div className="row no-gutters">
                    <Col md={7}></Col>
                    <Col>
                        <p style={{ fontSize: 12, marginBottom: 0, marginLeft: 15 }}>{imageName}</p>
                    </Col>
                </div>
            </Card.Header>


            {riskStatusList && <Card.Body>
                <Row>
                    <Col md={1}>
                        <div className={riskElementarStatus ?? "riskDotNeutral"}></div>
                    </Col>
                    <Col md={3}>Elementar</Col><Col md={3}>{getDeckungsCount(1)}</Col>
                </Row>
                <Row>
                    <Col md={1}>
                        <div className={riskEinbruchStatus ?? "riskDotNeutral"}></div>
                    </Col>
                    <Col md={3}>Einbruch</Col><Col md={3}>{getDeckungsCount(2)}</Col></Row>
                <Row>
                    <Col md={1}>
                        <div className={riskFeuerStatus ?? "riskDotNeutral"}></div>
                    </Col>
                    <Col md={3}>Feuer</Col><Col md={3}>{getDeckungsCount(3)}</Col></Row>
                <Row>
                    <Col md={1}>
                        <div className={riskWasserStatus ?? "riskDotNeutral"}></div>
                    </Col>
                    <Col md={3}>Wasser</Col><Col md={3}>{getDeckungsCount(4)}</Col>
                </Row>
            </Card.Body>}

        </Card>
    )
}