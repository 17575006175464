import React, { useEffect, useState } from 'react';
import directus from '../../lib/directus';
import { Button, Row } from 'react-bootstrap';
import moment from 'moment';

import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FilterMatchMode } from 'primereact/api';
import { InputText } from 'primereact/inputtext';


import "primereact/resources/themes/bootstrap4-light-blue/theme.css";
import "primereact/resources/primereact.min.css";
import {useNavigate} from "react-router-dom";
import {atom, useAtom} from 'jotai'
import { atomRiskColor, atomTriggerStatus } from '../checks';
import { atomChangesDone } from '../../App';
import { atomNumOfSelectedFeststellungen } from '../branchen/ModulHeader';
import { atomRisk, atomRiskList } from '../branchen/Deckungen';
import { atomFeststellungenValue } from '../branchen/ModulBody';

export const atomCheckID = atom();

//get checks from directus
const getChecks = async () => {
    const { data } = await directus.items('checks').readByQuery({});  //read all data from directus
    return data;  //return the data
};
//get berater from directus
const getBerater = async () => {
    const { data } = await directus.items('berater').readByQuery({});  //read all data from directus
    return data;  //return the data
};


export function Uebersicht(props) {
    const [sortedData, setSortedData] = useState([]);  //create a state for the sorted data
    const [sortData, setSortData] = useState([]);  //create a state for the sorted data
    const [checks, setChecks] = useState([]);  //create a state for the checks
    const [tableChecks] = useState([]);  //create a state for the checks
    const [filters, setFilters] = useState({
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
    });  //create a state for the filters
    const [, setIdCheck] = useAtom(atomCheckID);  //create a state for the checks

        /*  'atomTriggerStatus',
     'atomChangesDone',
     'atomCheckID',
     'atomNumOfSelectedFeststellungen',
     'atomRisk',
     'atomFeststellungenValue' */
     const [, setAtomTriggerStatus] = useAtom(atomTriggerStatus);
     const [, setAtomChangesDone] = useAtom(atomChangesDone);
     const [, setAtomCheckID] = useAtom(atomCheckID);
     const [, setAtomNumOfSelectedFeststellungen] = useAtom(atomNumOfSelectedFeststellungen);
     const [, setAtomRisk] = useAtom(atomRisk);
     const [, setAtomFeststellungenValue] = useAtom(atomFeststellungenValue);
     const [, setAtomRiskList] = useAtom(atomRiskList);
     const [, setRiskColorAtom ] = useAtom(atomRiskColor)

     useEffect(() => {
         setAtomTriggerStatus([{}]);
         setAtomChangesDone(false);
         setAtomCheckID('');
         setAtomNumOfSelectedFeststellungen([]);
         setAtomRisk([]);
         setAtomFeststellungenValue([]);
         setAtomRiskList([]);
         setRiskColorAtom([]);
     }, []);
 

    const navigate = useNavigate();



    // useEffect to get the checks from directus
    useEffect(() => {
        setIdCheck(null);

        async function fetchData() {
            const resultBerater = await getBerater();  //get the berater from directus
            const result = await getChecks();  //get the checks from directus
            //go through every object of result and change  the date format and replace the id of the berater with the name of the berater
            result.forEach((check) => {
                check.datumErstellt = new Date(check.datumErstellt)
                check.fkVBerater = resultBerater.find(berater => berater.idBerater === check.fkVBerater).beraterName;
                check.fkPBerater = resultBerater.find(berater => berater.idBerater === check.fkPBerater).beraterName;
                check.lagerbuchNr = check.ortsCode + '-' + check.objektCode;
            });

            setChecks(result);
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    useEffect(() => {
        setSortData(checks.sort((a, b) => new Date(a.datumErstellt) - new Date(b.datumErstellt)));
    }, [checks]);

    useEffect(() => {
        sortData.forEach(data => {
            // for every entry in the sortData array, set setSortedData to the sortData object and change the date format to string
            setSortedData((sortedData) => [
                ...sortedData,
                {
                    ...data,
                    datumErstellt: moment(data.datumErstellt),
                },
            ]);
        });
    }, [sortData]);



    const handleCreateCheck = () => {
        setIdCheck('');
        navigate(`/allgemein`, { state: { idChecks: null } });
    }

    const handleDownloadAllAuswertungen = () => {
        async function exportCsv() {
          try {
            const items = await directus.items('auswertungen').readByQuery({
              fields: [
                  'fkCheck.idChecks',
                'idAuswertungen',
                'bemerkungen',
                'fkCheck.datumErstellt',
                'fkCheck.ortsCode',
                'fkCheck.objektCode',
                'fkCheck.primEigentuemerGeschlecht',
                'fkCheck.primEigentuemer',
                'fkCheck.primEigentuemerNName',
                'fkCheck.secEigentuemerGeschlecht',
                'fkCheck.secEigentuemer',
                'fkCheck.secEigentuemerNName',
                'fkCheck.ort',
                'fkCheck.plz',
                'fkCheck.strasse',
                'fkCheck.kommentar',
                'fkCheck.status',
                'fkCheck.elementarRisiko',
                'fkCheck.wasserRisiko',
                'fkCheck.einbruchRisiko',
                'fkCheck.feuerRisiko',
                'fkCheck.fkVBerater.idBerater',
                'fkCheck.fkVBerater.beraterName',
                'fkCheck.fkPBerater.idBerater',
                'fkCheck.fkPBerater.beraterName',
                'fkDeckung.modulNr',
                'fkDeckung.modulName',
                'fkDeckung.deckungName',
                'fkDeckung.idDeckungen',
                'fkDeckung.fkBranchen.branchenName',
                'fkDeckung.fkBranchen.idBranchen',
                'fkDeckung.fkStatus',
                'fkFeststellung.feststellungName',
                'fkFeststellung.textBericht',
                'fkFeststellung.vorschlagBericht',
                'fkFeststellung.status',
                'fkFeststellung.idFeststellungen',
              ],
              limit: -1, // get all items
              sort: 'fkCheck.idChecks,fkDeckung.reihenfolgeGui,idAuswertungen', // sort by fkCheck.idChecks field first, then by fkDeckung.reihenfolgeGui field
            });
      
            if (items && items.data) {
              const fields = [
                  'fkCheck.idChecks',
                'idAuswertungen',
                'bemerkungen',
                'fkCheck.datumErstellt',
                'fkCheck.ortsCode',
                'fkCheck.objektCode',
                'fkCheck.primEigentuemerGeschlecht',
                'fkCheck.primEigentuemer',
                'fkCheck.primEigentuemerNName',
                'fkCheck.secEigentuemerGeschlecht',
                'fkCheck.secEigentuemer',
                'fkCheck.secEigentuemerNName',
                'fkCheck.ort',
                'fkCheck.plz',
                'fkCheck.strasse',
                'fkCheck.kommentar',
                'fkCheck.status',
                'fkCheck.elementarRisiko',
                'fkCheck.wasserRisiko',
                'fkCheck.einbruchRisiko',
                'fkCheck.feuerRisiko',
                'fkCheck.fkVBerater.idBerater',
                'fkCheck.fkVBerater.beraterName',
                'fkCheck.fkPBerater.idBerater',
                'fkCheck.fkPBerater.beraterName',
                'fkDeckung.modulNr',
                'fkDeckung.modulName',
                'fkDeckung.deckungName',
                'fkDeckung.idDeckungen',
                'fkDeckung.fkBranchen.branchenName',
                'fkDeckung.fkBranchen.idBranchen',
                'fkDeckung.fkStatus',
                'fkFeststellung.feststellungName',
                'fkFeststellung.textBericht',
                'fkFeststellung.vorschlagBericht',
                'fkFeststellung.status',
                'fkFeststellung.idFeststellungen',
              ];
              const title = [
                'idChecks',
                'idAuswertungen',
                'bemerkungen',
                'erstellDatum',
                'ortsCode',
                'objektCode',
                'primEigentuemerGeschlecht',
                'primEigentuemer',
                'primEigentuemerNName',
                'secEigentuemerGeschlecht',
                'secEigentuemer',
                'secEigentuemerNName',
                'ort',
                'plz',
                'strasse',
                'kommentar',
                'checkStatus',
                'elementarRisiko',
                'wasserRisiko',
                'einbruchRisiko',
                'feuerRisiko',
                'idBerater',
                'beraterName',
                'idBerater',
                'beraterName',
                'modulNr',
                'modulName',
                'deckungName',
                'idDeckungen',
                'branchenName',
                'idBranchen',
                'fkStatus',
                'feststellungName',
                'textBericht',
                'vorschlagBericht',
                'feststellungsStatus',
                'idFeststellungen',
              ];
      
              const csvData = [];
              
              // Add the field names as the first row in the CSV
              csvData.push(title.join(','));
      
              // Add data rows
              items.data.forEach(item => {
                const row = fields.map(field => {
                  const value = getFieldValue(item, field);
                  return value !== undefined ? value : '';
                });
                csvData.push(row.join(','));
              });
      
              const csvContent = `data:text/csv;charset=utf-8,${csvData.join('\n')}`;
              const encodedUri = encodeURI(csvContent);
              const link = document.createElement('a');
              link.setAttribute('href', encodedUri);
              link.setAttribute('download', 'auswertungen.csv');
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          } catch (error) {
            console.error('Error exporting CSV:', error);
          }
        }
      
        exportCsv();
      };

      function getFieldValue(item, field) {
        const fields = field.split('.');
        let value = item;
        for (const f of fields) {
          if (value && value.hasOwnProperty(f)) {
            value = value[f];
          } else {
            value = undefined;
            break;
          }
        }
        return value;
      }

    return (
        // create a div class container to hold the table with the max width of 100%
        <div>
            <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Button variant='secondary' className='mt-3'
                    style={{ paddingBottom: 15, marginBottom: 17.5, marginLeft: 17.5 }} onClick={handleCreateCheck}>Neuen
                    Check erstellen</Button>
                <InputText
                    onInput={(e) =>
                        setFilters({
                            global: { value: e.target.value, matchMode: FilterMatchMode.CONTAINS },
                        })
                    }
                    placeholder='Suche'
                    type='search'
                    style={{ marginLeft: 17.5 }}
                />

            </Row>
            <DataTable
                value={sortedData}
                className='p-datatable-striped'
                rowHover={true}
                filters={filters}
                sortMode='multiple'
                multiSortMeta={[{ field: 'datumErstellt', order: -1 }]}
                selectionMode='single'
                selection={tableChecks}
                onRowClick={(e, row) => {
                    setIdCheck(e.data.idChecks)
                    navigate(`/allgemein`, { state: { idChecks: e.data.idChecks } })
                }}
                paginator={true}
                rows={5}
                rowsPerPageOptions={[5, 10, 25]}
                totalRecords={checks.length}
            >
                <Column field='lagerbuchNr' header='Lagerbuch-Nr' sortable={true} style={{ width: '5%' }} />
                <Column field='primEigentuemer' header='Vorname' sortable={true} style={{ width: '10%' }} />
                <Column field='primEigentuemerNName' header='Nachname' sortable={true} style={{ width: '10%' }} />
                <Column field='strasse' header="Adresse" sortable={true} style={{ width: '10%' }} />
                <Column field="plz" header="PLZ" sortable={true} style={{ width: '5%' }} />
                <Column field="ort" header="Ort" sortable={true} style={{ width: '10%' }} />
                <Column dataType={"date"} field="datumErstellt" header="Datum" sortable={true} style={{ width: '10%' }}
                    body={(data) => {
                        return data.datumErstellt.format('DD.MM.YYYY')
                    }}
                />
                <Column field="fkPBerater" header="Präv. Experte" sortable={true} style={{ width: '10%' }} />
                <Column field="fkVBerater" header="Vers. Berater" sortable={true} style={{ width: '10%' }} />
                <Column field="status" header="Status" sortable={true} style={{ width: '10%' }} />
            </DataTable>
            <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <Button variant='secondary' className='mt-3' style={{ paddingBottom: 15, marginBottom: 17.5, marginLeft: 17.5 }} onClick={handleDownloadAllAuswertungen}>Daten als CSV Downloaden</Button>
            </Row>
        </div>

    )
}



