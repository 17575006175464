import directus from '../../lib/directus';
import React, { useEffect, useState } from 'react'
import { Button, Card } from 'react-bootstrap';
import { useAtom } from "jotai/index";
import { atomChangesDone } from "../../App";
import { useNavigate } from "react-router-dom";
import { atomCheckID } from "../uebersicht";
import ModulHeader from '../branchen/ModulHeader';

async function getData() {
    const { data } = await directus.items('deckungen').readByQuery({
        filter: {
            fkBranchen: '3',
        },
        groupBy: ['modulName', 'modulNr'],
    });
    return data;
}

async function getData2() {
    const { data } = await directus.items('deckungen').readByQuery({
        filter: {
            fkBranchen: '3',
        },
        fields: ['modulNr', 'modulName', 'reihenfolgeGui'],
    });
    return data;
}


//This Component is used to import the individual modul data to generate components.

export function Elementar({ handleClick, updateData, idCheckProp, setIdCheckProp }) {
    //object of "module" where "branche" = elementar
    const [modul, setModul] = useState(['']);
    const [changesDone, ] = useAtom(atomChangesDone);

    const navigate = useNavigate();
    const [idCheck] = useAtom(atomCheckID);

    //Importing data from mydb/module (Only data with the "branche": "Elementar")
    useEffect(() => {
        async function fetchData() {
            const result = await getData();
            const result2 = await getData2();

            //USE THE data in result2 to sort the modul array by reihenfolgeGui where modulNr (from resul) = modulNr (from result2)
            for (var i = 0; i < result.length; i++) {
                for (var j = 0; j < result2.length; j++) {
                    if (result[i].modulNr === result2[j].modulNr) {
                        result[i].reihenfolgeGui = result2[j].reihenfolgeGui;
                    }
                }
            }
            setModul(result);
        }

        fetchData();
    }, []);

    if (!idCheck) navigate('/uebersicht');

    //Call the next components and pass the object with all the "Elementar" data
    return (
        <div className='overviewContent'>
            <Button style={{
                marginBottom: '10px',
                background: !changesDone ? "#ccc" : "#007bff",
                borderColor: !changesDone ? "#ccc" : "#007bff",
                display: 'block',
                marginLeft: 'auto',
                //move the button all the way to the right, and keep it above the cards
            }}
                disabled={!changesDone} onClick={handleClick}>Speichern</Button>
            {
                //generate a "ModulHeader" component for every module that's in the "modul" object
                modul
                    .sort(function (a, b) {
                        return a.reihenfolgeGui - b.reihenfolgeGui
                    })
                    .map((modul, i) => {
                        return (
                            <Card key={i} className='mb-3'>
                                <ModulHeader modul={modul} updateData={updateData} />
                            </Card>
                        )
                    })
            }
        </div>
    );
}
