import directus from '../lib/directus';
import React, {useEffect, useMemo, useState} from 'react'
import {Dropdown, DropdownButton} from 'react-bootstrap'

async function getGlobals() {
    const {data} = await directus.items('berater').readByQuery();
    return data;
}

export default function CustomDropdown({gespeicherterBerater, isVBerater, handleChange}) {
    const [value, setValue] = useState('');
    const [berater, setBerater] = useState([]);

    const handleSelect = (e) => {
        handleChange(e);
        setValue(e.target.getAttribute('value'));
    };

    useMemo(() => {
        setValue(gespeicherterBerater);
    }, [gespeicherterBerater]);

    useEffect(() => {
        async function fetchData() {
            const result = await getGlobals();
            setBerater(result);
        }

        fetchData();
    }, []);
    return (
        <div>
            <DropdownButton
                id="objectDropdown"
                title={berater.some(item => item.idBerater === value) ? berater.find(item => item.idBerater === value).beraterName : (isVBerater ? 'Berater auswählen' : 'Experten auswählen')}
                size="md"
                variant="secondary"
            >
                {berater.sort((a, b) => {
                    // sort by lastname and firstname
                    if (a.beraterName.split(' ').pop() < b.beraterName.split(' ').pop()) {
                        return -1;
                    } else if (a.beraterName.split(' ').pop() > b.beraterName.split(' ').pop()) {
                        return 1;
                    }
                    return 0;
                }).map((beratender, i) => {
                        if (isVBerater === 1 && beratender.isVersicherungsberater === true) {
                            return <Dropdown.Item name='fkVBerater' value={beratender.idBerater}
                                                  eventKey={beratender.idBerater} key={i}
                                                  onClick={(e) => handleSelect(e)}>{beratender.beraterName}</Dropdown.Item>
                        } else if (isVBerater === 0 && beratender.isVersicherungsberater === false) {
                            return <Dropdown.Item name='fkPBerater' value={beratender.idBerater}
                                                  eventKey={beratender.idBerater} key={i}
                                                  onClick={(e) => handleSelect(e)}>{beratender.beraterName}</Dropdown.Item>
                        } else return null
                    }
                )}
            </DropdownButton>
        </div>
    )
}
