import {createSlice} from '@reduxjs/toolkit';


export const userSlice = createSlice({
    name: 'idCheck',
    initialState: {
        id: 14,
    },
    reducers: {
        setCheck: (state, action) => {
            state.id = action.payload;
        },
    },
});

export const {setCheck} = userSlice.actions;


export default userSlice.reducer;