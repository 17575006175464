import React, { useEffect } from 'react'
import { Col, Dropdown, DropdownButton, Form, Row } from 'react-bootstrap'
export default function DeckungenParent({
    deckung,
    bemerkung,
    setBemerkung,
    riskName,
    feststellungen,
    value,
    handleSelect,
    updateFeststellungen,
    setChangesDone,
    isUnterposition,
}) {
    useEffect(() => {
        updateFeststellungen();
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [riskName])
    if (isUnterposition === 0) {
        return (
            <Form className='mb-1'>
                <Form.Group>
                    <Row>
                        <Col md={3}>
                            {deckung.deckungName}
                        </Col>
                        <Col md={5}>
                            <input type="text" value={bemerkung ? bemerkung : ""} onChange={(e) => {
                                setChangesDone(true);
                                setBemerkung(e.target.value)
                            }} style={{ width: '100%' }} />
                        </Col>
                        <Col> {/* This Component of the react bootstrap framework contains a bug: shows a "Popper:" warning in the console of client */}
                            <div className={riskName}></div>
                        </Col>
                        <Col md={3}>
                            {/* This Component of the react bootstrap framework contains a bug: shows a "Popper:" warning in the console of client */}
                            <DropdownButton
                                id={`feststellungDropdown`}
                                size="sm"
                                variant="secondary"
                                title={value ? value : "Wählen"}
                                onSelect={handleSelect}
                            >
                                {feststellungen?.map((feststellung, i) =>
                                    <Dropdown.Item key={i} eventKey={feststellung.idFeststellungen}>
                                        {feststellung.feststellungName}
                                    </Dropdown.Item>
                                )}
                            </DropdownButton>
                        </Col>
                    </Row>
                </Form.Group>
            </Form>
        )
    } else {
        return (
            <Form className='mb-1'>
            <Form.Group>
                <Row>
                    <div style={{ marginLeft: "5%" }}/>
                    <Col md={2}>
                        {deckung.deckungName}
                    </Col>
                    <div style={{ marginLeft: "3.25%" }}/>
                    <Col md={5}>
                        <input type="text" value={bemerkung ? bemerkung : ""} onChange={(e) => {
                            setChangesDone(true);
                            setBemerkung(e.target.value)
                        }} style={{ width: '100%' }} />
                    </Col>
                    <Col> {/* This Component of the react bootstrap framework contains a bug: shows a "Popper:" warning in the console of client */}
                        <div className={riskName}></div>
                    </Col>
                    <Col md={3}>
                        {/* This Component of the react bootstrap framework contains a bug: shows a "Popper:" warning in the console of client */}
                        <DropdownButton
                            id={`feststellungDropdown`}
                            size="sm"
                            variant="secondary"
                            title={value ? value : "Wählen"}
                            onSelect={handleSelect}
                        >
                            {feststellungen?.map((feststellung, i) =>
                                <Dropdown.Item key={i} eventKey={feststellung.idFeststellungen}>
                                    {feststellung.feststellungName}
                                </Dropdown.Item>
                            )}
                        </DropdownButton>
                    </Col>
                </Row>
            </Form.Group>
        </Form>
        )
    }
}
