// Create styles
import {Font, StyleSheet} from "@react-pdf/renderer";


import BoldArial from "../fonts/arial_bold.ttf";
import Arial from "../fonts/arial.ttf";

Font.register({
    family: "Arial",
    fontStyle: "normal",
    fontWeight: "normal",
    fonts: [
        {
            src: Arial,
        },
        {
            src: BoldArial,
            fontWeight: "bold",
        },
    ],
});

export const styles = StyleSheet.create({
    secondaryButton: {
        appearance: "none",
    },
    inline: {
        display: "inline",
    },
    red: {
        color: "rgb(176, 0, 4)",
        fontWeight: "bold"
    },
    page: {
        fontFamily: "Arial",
        backgroundColor: '#fff',
        padding: "2cm 1.3cm 1.3cm 2cm",
        fontSize: 10,
        lineHeight: 1.2
    },
    cover_page: {
        fontFamily: "Arial",
        backgroundColor: '#fff',
        padding: "2.5cm 2.5cm 2.0cm 2.5cm",
        fontSize: 10,
        lineHeight: 1.2
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: 20,
    },
    groupTitle: {
        fontSize: 11,
        fontWeight: "bold",
        marginBottom: 11,
    },
    logo: {
        width: "50%",
        height: "auto",
        objectFit: "contain",
    },
    header: {
        position: 'absolute',
        top: "1.5cm",
        right: "1.5cm",
        left: "1.5cm",
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        margin: 0,
        padding: 0,
    },
    footer: {
        position: 'absolute',
        fontSize: 10,
        bottom: "0.6cm",
        right: "1.3cm",
        left: "2cm",
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
        margin: 0,
        padding: "6px 0 0 0",
        borderTop: "1px solid grey",
    },
    footerText: {
        fontSize: 8,
        color: 'black',
        width: "90%",
        margin: 0,
        padding: 0
    },
    footerTextPage1: {
        fontSize: 8,
        width: "40%",
        margin: 0,
        padding: 0,
        color: "#634A13",
    },
    pageNumber: {
        fontSize: 8,
        color: 'black',
        width: "10%",
        margin: 0,
        padding: 0,
        textAlign: 'right'
    },
    image: {
        maxWidth: "100%",
        height: "auto",
        maxHeight: "250px",
        marginRight: "30%"
    },
    headline1: {
        fontSize: 36,
        lineHeight: 1,
        marginTop: 100,
        fontWeight: "bold",
    },
    headline2: {
        fontSize: 36,
        lineHeight: 1,
        fontWeight: "bold",
    },
    objectImage: {
        border: "1px solid grey",
        backgroundColor: 'grey',
        marginTop: 20,
        maxWidth: "13cm",
        maxHeight: "9cm",
    },
    objectText: {
        fontSize: 14,
        marginTop: "14pt",
        fontWeight: "bold",
    },
    objectTextSmall: {
        fontSize: 10,
        marginTop: 4,
    },
    toc: {
        minHeight: 100,
        marginTop: 50,
        marginBottom: 25,
        width: "13cm",
    },
    section: {
        header: {
            fontSize: 22,
            lineHeight: 1.25,
            borderBottom: "1pt solid #000000",
            marginBottom: 18,
        }
    },
    text_normal: {
        fontSize: 10,
    },
    text: {
        marginBottom: 10,
        fontSize: 11,
    },
    textIntro: {
        marginBottom: 10,
        fontSize: 10,
    },
    greetings: {
        marginTop: 30,
        fontSize: 10,
    },
    bolder: {
        fontWeight: "bold",
        fontSize: 12,
        lineHeight: 1.16,
    },
    bold: {
        fontWeight: "bold",
    },
    list: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        width: "80%",
    },
    listItem: {
        paddingLeft: 20,
        paddingRight: 10,
    },
    signature: {
        width: 150,
        maxHeight: "1.4cm",
        marginTop: 0,
        marginBottom: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'flex-start',
    },
    columns: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: "2cm",
        width: "10cm",
        marginTop: 10,
    },
    column_child: {
        width: 150,
        marginTop: 0,
        marginBottom: 0,
    },
    names: {
        fontSize: 10,
    },
    small: {
        fontSize: 8,
    },
    smaller: {
        fontSize: 6,
    },
    riskDotNeutral: {
        height: "10px",
        width: "10px",
        backgroundColor: "#bbb",
        borderRadius: "50%",
        display: "inline-block",
        textAlign: "center",
    },
    riskSquareLow: {
        height: "10px",
        width: "10px",
        backgroundColor: "green",
        display: "inline-block",
        textAlign: "center",
    },
    riskTriangleMid: {
        display: "inline-block",
        textAlign: "center",
        width: "0px",
        background: "antiquewhite",
        borderTop: "0px solid white",
        borderBottom: "10px solid orange",
        borderLeft: "5px solid white",
        borderRight: "5px solid white"
    },
    riskDotHigh: {
        height: "10px",
        width: "10px",
        backgroundColor: "rgb(176, 0, 4)",
        borderRadius: "50%",
        display: "inline-block",
        textAlign: "center",
    },
    branche: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        gap: 5,
        marginTop: 15,
        marginBottom: 2,

        group: {
            marginBottom: 20
        }
    },
    deckung_modul: {
        marginBottom: 20,
    },
    deckung: {
        info: {
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'flex-start',
            marginTop: 10,
            marginBottom: 2,
            width: "100%",
        },
        group: {
            marginRight: 40,
            width: "60%",
            maxWidth: "60%",
            fontSize: 10,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 10,
            flexGrow: 1,
            flexShrink: 1
        },
        additionalInfo: {
            width: "40%",
            fontSize: 10,
            flexGrow: 0,
            flexShrink: 0
        }
    },
    flex_component1: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: 0,
        width: "94mm",
        fontSize: 10,
    },
    flex_component2: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        width: "65mm",
        fontSize: 10,
        marginLeft: 20,
    },
    flex_children: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
        gap: 0,
        marginBottom: 10
    },
    textFeststellung: {
        fontSize: 9,
    },
    info_group: {
        marginBottom: 15,
    }
});