import {NavLink, useNavigate} from "react-router-dom";
import directus from "../lib/directus";
import {Button} from "react-bootstrap";
import {useAtom} from "jotai";
import {atomAuthenticated, atomChangesDone, atomUsername} from "../App";
import {atomCheckID} from "./uebersicht";


export const Navigation = () => {

    const [authenticated, setAuthenticated] = useAtom(atomAuthenticated);
    const [username, setUsername] = useAtom(atomUsername);
    const navigate = useNavigate();
    const [changesDone, setChangesDone] = useAtom(atomChangesDone)
    const [checkID] = useAtom(atomCheckID)

    const handleClick = (e) => {
        // if (changesDone) {
        //     if (window.confirm("Es wurden Änderungen vorgenommen, die noch nicht gespeichert wurden. Wollen Sie die Seite trotzdem verlassen?")) {
        //         setChangesDone(false);
        //     } else {
        //         e.preventDefault();
        //     }
        // }
    }
    const handleClickSpecial = (e) => {
        if (changesDone) {
            if (window.confirm("Es wurden Änderungen vorgenommen, die noch nicht gespeichert wurden. Wollen Sie die Seite trotzdem verlassen?")) {
                setChangesDone(false);
                for(let key in localStorage){
                    if(!key.startsWith("auth_")){
                        localStorage.removeItem(key);
                    }
                }
                navigate("/uebersicht");
            } else {
                e.preventDefault();
            }
        } else {
            for(let key in localStorage){
                if(!key.startsWith("auth_")){
                    localStorage.removeItem(key);
                }
            }
        }
    }

    //Render all the Buttons with all the links
    return (
        <>
            <div className="nav-bar-container">
                <nav>
                    <NavLink
                        to="/uebersicht"
                        className={({isActive}) => (isActive ? "link-active" : "link")}
                        onClick={handleClickSpecial}
                    >
                        Übersicht
                    </NavLink>
                    <NavLink
                        to="/allgemein"
                        className={({isActive}) => (isActive ? "link-active" : "link") + (checkID === null ? " disabled" : "")}
                        onClick={handleClick}
                        disabled={checkID === null}
                    >
                        Allgemein
                    </NavLink>
                    <NavLink
                        to="/elementar"
                        className={({isActive}) => (isActive ? "link-active" : "link") + (checkID === null ? " disabled" : "")}
                        onClick={handleClick}
                        disabled={checkID === null}
                    >
                        Elementar
                    </NavLink>
                    <NavLink
                        to="/einbruch"
                        className={({isActive}) => (isActive ? "link-active" : "link") + (checkID === null ? " disabled" : "")}
                        onClick={handleClick}
                        disabled={checkID === null}
                    >

                        Einbruch
                    </NavLink>
                    <NavLink
                        to="/feuer"
                        className={({isActive}) => (isActive ? "link-active" : "link") + (checkID === null ? " disabled" : "")}
                        onClick={handleClick}
                        disabled={checkID === null}
                    >
                        Feuer
                    </NavLink>
                    <NavLink
                        to="/wasser"
                        className={({isActive}) => (isActive ? "link-active" : "link") + (checkID === null ? " disabled" : "")}
                        onClick={handleClick}
                        disabled={checkID === null}
                    >
                        Wasser
                    </NavLink>
                    {(authenticated || username) && <Button style={{marginLeft: 20}} onClick={() => {
                        directus.auth.logout().then(() => {
                            localStorage.clear();
                            setAuthenticated(false)
                            setUsername()
                            navigate("/login");
                        }).catch(() => {
                            localStorage.clear();
                            setAuthenticated(false)
                            navigate("/login", {replace: true});
                        });
                    }} className="btn btn-danger">Logout</Button>}
                </nav>
            </div>
        </>

    );
};
