import {Link, Text, View} from "@react-pdf/renderer";
import {styles} from "../styles/styles";
import {BFBLogo} from "../bfb_logo";

export function AdviceFire() {

    return <View wrap={false}>
        <Text style={styles.groupTitle}>Tipps</Text>
        <Text style={[styles.textFeststellung, styles.bold]}>Richtiges Verhalten bei einem Brand</Text>
        <Text style={styles.textFeststellung}>Das richtige Verhalten bei einem Brand ist entscheidend, um Menschen, Tiere und Ihr
            Zuhause zu schützen. Bei einem Brand gilt immer: <Text style={styles.red}>Alarmieren (Feuerwehr 118) –
                Retten – Löschen.</Text></Text>
        <Text style={[styles.textFeststellung, styles.bold]}>Heizen, aber sicher</Text>
        <View style={styles.list}><Text style={styles.listItem}>•</Text><Text style={styles.textFeststellung}>Halten Sie die Türen zum Heizraum aus
            Sicherheitsgründen immer geschlossen</Text></View>
        <View style={styles.list}>
            <Text style={styles.listItem}>•</Text><Text style={styles.textFeststellung}>Bitte verwenden Sie für Holzfeuerungen
            immer dürres, trockenes und naturbelassenen (unbehandeltes) Holz. Damit schonen Sie nicht nur Ihre Anlage,
            sondern auch die Umwelt.

        </Text>
        </View>
        <View>
            <View style={[styles.flex, {gap: 4, alignItems: "flex-end", marginTop: 10}]}><BFBLogo/><Text style={[styles.textFeststellung, styles.bold]}>BFB Beratungsstelle für
                Brandverhütung</Text></View>
            <Text style={styles.textFeststellung}>Seit über 60 Jahren setzt die Beratungsstelle für Brandverhütung BFB dort an, wo
                die meisten Brände entstehen beim menschlichen Verhalten. Durch gezielte Informationen zu den
                Brandrisiken will die BFB eine Verhaltensänderung bei der Bevölkerung herbeiführen. Es sollen Brände
                verhütet und Schäden an Personen, Tieren, Sachen und der Umwelt verhindert werden. Auf der Website der
                BFB www.bfb-cipi.ch finden Sie wertvolle Brandverhütungs-Tipps und Informationen zu Themen wie</Text>
            <View style={styles.list}><Text style={styles.listItem}>•</Text><Text style={styles.textFeststellung}>Elektrische Geräte als Brandursache</Text></View>
            <View style={styles.list}><Text style={styles.listItem}>•</Text><Text style={styles.textFeststellung}>Sicher Grillieren</Text></View>
            <View style={styles.list}><Text style={styles.listItem}>•</Text><Text style={styles.textFeststellung}>Kinder und Feuer</Text></View>
            <View style={styles.list}><Text style={styles.listItem}>•</Text><Text style={styles.textFeststellung}>u.v.m.
            </Text></View>
        </View>
        <Text style={[styles.textFeststellung, styles.bold]}>Rauchmelder</Text>
        <Text style={styles.textFeststellung}>Das Schema für die korrekte und wirkungsvolle Installation der Rauchmelder finden Sie
            unter <Link break={"none"} src={"www.glarnersach.ch/schema"}>www.glarnersach.ch/schema</Link></Text>
    </View>
}