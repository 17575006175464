import {styles} from "./styles/styles";
import {Image, Page, Text, View} from "@react-pdf/renderer";
import {Footer} from "./footer";
import React from "react";
import {TocReference} from "./toc/tocReference";
import {appUrl} from "../../lib/directus";

export function Intro({dataChecks}) {
    let person1 = {
        "greetings": `Sehr geehrte${dataChecks.primEigentuemerGeschlecht === "Herr" ? "r" : ""}`,
        "sex": dataChecks.primEigentuemerGeschlecht,
        "name": dataChecks.primEigentuemer,
        "surname": dataChecks.primEigentuemerNName
    }
    let person2 = {
        "greetings": `Sehr geehrte${dataChecks.secEigentuemerGeschlecht === "Herr" ? "r" : ""}`,
        "sex": dataChecks.secEigentuemerGeschlecht,
        "name": dataChecks.secEigentuemer,
        "surname": dataChecks.secEigentuemerNName
    }
    if (person2?.sex !== "Herr") {
        let personTemp = person1;
        person1 = person2;
        person2 = personTemp;
    }
    return <Page bookmark={{fit: true, title: "Einleitung"}} key={"page_intro"} wrap={true} size="A4"
                 style={styles.page}>
        <View wrap={false}>
            <Text id={"bookmark_intro"} style={styles.section.header}>Einleitung</Text>
            <TocReference id={"item8"}/>
            {person1.name && <Text style={[styles.textIntro, {marginBottom: person2.name ? 0 : 10}]} render={() => (`${person1.greetings} ${person1.sex} ${person1.surname}`)}/>}
            {person2.name && <Text style={[styles.textIntro]} render={() => (`${person2.greetings} ${person2.sex} ${person2.surname}`)}/>}
            <Text style={[styles.textIntro, {marginBottom: 0}]}>
                Wir freuen uns, dass Sie sich für unseren Gebäudecheck EFH für Ihr Eigenheim entschieden haben.
                Unser Ziel ist, Ihnen bestehende Gefahren, Mängel und Schwachstellen am Gebäude und/oder in Ihrem
                Verhalten aufzuzeigen. Damit, so hoffen wir, können wir Sie und Ihre Liebsten vor Schäden und deren
                finanziellen Folgen bewahren.

            </Text>
            <Text style={styles.textIntro}>
                Nicht alle Schadenereignisse lassen sich mit präventiven Massnahmen und richtigem Verhalten
                verhindern. Die so entstehenden finanziellen Folgen lassen sich mit gezielten Versicherungsdeckungen
                absichern. Sie finden am Schluss dieses Berichts eine Übersicht zu nötigen bzw. sinnvollen
                Versicherungsdeckungen.

            </Text>
            <Text style={styles.textIntro}>
                Auf den folgenden Seiten finden Sie die detaillierten Resultate unseres Gebäudechecks EFH. Diese
                sind in die Rubriken «Feuer», «Elementar», «Wasser» und «Einbruch» unterteilt. Der Bericht enthält
                Vorschläge und Empfehlungen zu Optimierungs- und Verbesserungsmassnahmen.

            </Text>
            <Text style={styles.textIntro}>
                Hingegen können wir Ihnen bestätigen, dass die Ergebnisse dieses Berichts keinerlei Einfluss auf
                Versicherungsdeckungen oder Schadenzahlungen haben.

            </Text>
            <Text style={styles.textIntro}>
                Wir danken Ihnen für das uns entgegengebrachte Vertrauen. Bei Fragen oder Unklarheiten stehen Ihnen
                unsere Präventionsexperten und Versicherungsberater gerne mit Rat und Tat zur Verfügung. Weitere
                hilfreiche Tipps und Ratschläge zu Ihrer Sicherheit finden Sie auf unter www.glarnersach.ch/tipps.
            </Text>
            <View style={styles.greetings}>
                <Text>Freundliche Grüsse</Text>
                <Text style={styles.bolder}>Ihre glarnerSach</Text>
                <View style={styles.columns}>
                    <View style={styles.signature}>
                        <Image src={`${appUrl}/assets/${dataChecks.fkPBerater.Signatur}`}/>}
                    </View>
                    <View style={styles.signature}>
                        <Image src={`${appUrl}/assets/${dataChecks.fkVBerater.Signatur}`}/>}
                    </View>
                </View>
                <View style={styles.columns}>
                    <View style={styles.column_child}>
                        <Text style={styles.names} render={() => dataChecks.fkPBerater.beraterName}/>
                        <Text style={styles.smaller}>Präventionsexperte</Text>
                    </View>
                    <View style={styles.column_child}>
                        <Text style={styles.names} render={() => dataChecks.fkVBerater.beraterName}/>
                        <Text style={styles.smaller}>Versicherungsberater</Text>
                    </View>
                </View>
            </View>
            <View style={{marginTop: 40}}>
                <Text style={styles.small}>Bei diesem Gebäudecheck EFH handelt es sich ausschliesslich um optische
                    Begutachtungen, weshalb wir für unsere Feststellungen keine Garantie bzw. Haftung übernehmen
                    können. Wenden Sie sich, wie im Bericht empfohlen, an die entsprechenden Fachpersonen.</Text>
            </View>
        </View>
        <Footer keyValue={"intro_footer"} styles={styles} dataChecks={dataChecks}/>
    </Page>
}